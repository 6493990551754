// source: yorkie/v1/resources.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.yorkie.v1.Change', null, global);
goog.exportSymbol('proto.yorkie.v1.ChangeID', null, global);
goog.exportSymbol('proto.yorkie.v1.ChangePack', null, global);
goog.exportSymbol('proto.yorkie.v1.Checkpoint', null, global);
goog.exportSymbol('proto.yorkie.v1.Client', null, global);
goog.exportSymbol('proto.yorkie.v1.Clients', null, global);
goog.exportSymbol('proto.yorkie.v1.DocEvent', null, global);
goog.exportSymbol('proto.yorkie.v1.DocEventType', null, global);
goog.exportSymbol('proto.yorkie.v1.DocumentSummary', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement.BodyCase', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement.Counter', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement.JSONArray', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement.JSONObject', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement.Primitive', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement.RichText', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElement.Text', null, global);
goog.exportSymbol('proto.yorkie.v1.JSONElementSimple', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Add', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.BodyCase', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Edit', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Increase', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Move', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Remove', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.RichEdit', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Select', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Set', null, global);
goog.exportSymbol('proto.yorkie.v1.Operation.Style', null, global);
goog.exportSymbol('proto.yorkie.v1.Presence', null, global);
goog.exportSymbol('proto.yorkie.v1.Project', null, global);
goog.exportSymbol('proto.yorkie.v1.RGANode', null, global);
goog.exportSymbol('proto.yorkie.v1.RHTNode', null, global);
goog.exportSymbol('proto.yorkie.v1.RichTextNode', null, global);
goog.exportSymbol('proto.yorkie.v1.RichTextNodeAttr', null, global);
goog.exportSymbol('proto.yorkie.v1.TextNode', null, global);
goog.exportSymbol('proto.yorkie.v1.TextNodeID', null, global);
goog.exportSymbol('proto.yorkie.v1.TextNodePos', null, global);
goog.exportSymbol('proto.yorkie.v1.TimeTicket', null, global);
goog.exportSymbol('proto.yorkie.v1.UpdatableProjectFields', null, global);
goog.exportSymbol('proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods', null, global);
goog.exportSymbol('proto.yorkie.v1.User', null, global);
goog.exportSymbol('proto.yorkie.v1.ValueType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.ChangePack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.ChangePack.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.ChangePack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.ChangePack.displayName = 'proto.yorkie.v1.ChangePack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Change = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.Change.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.Change, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Change.displayName = 'proto.yorkie.v1.Change';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.ChangeID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.ChangeID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.ChangeID.displayName = 'proto.yorkie.v1.ChangeID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.yorkie.v1.Operation.oneofGroups_);
};
goog.inherits(proto.yorkie.v1.Operation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.displayName = 'proto.yorkie.v1.Operation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Set = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Set, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Set.displayName = 'proto.yorkie.v1.Operation.Set';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Add = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Add, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Add.displayName = 'proto.yorkie.v1.Operation.Add';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Move = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Move, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Move.displayName = 'proto.yorkie.v1.Operation.Move';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Remove = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Remove, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Remove.displayName = 'proto.yorkie.v1.Operation.Remove';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Edit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Edit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Edit.displayName = 'proto.yorkie.v1.Operation.Edit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Select = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Select, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Select.displayName = 'proto.yorkie.v1.Operation.Select';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.RichEdit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.RichEdit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.RichEdit.displayName = 'proto.yorkie.v1.Operation.RichEdit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Style = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Style, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Style.displayName = 'proto.yorkie.v1.Operation.Style';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Operation.Increase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Operation.Increase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Operation.Increase.displayName = 'proto.yorkie.v1.Operation.Increase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElementSimple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.JSONElementSimple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElementSimple.displayName = 'proto.yorkie.v1.JSONElementSimple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.yorkie.v1.JSONElement.oneofGroups_);
};
goog.inherits(proto.yorkie.v1.JSONElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElement.displayName = 'proto.yorkie.v1.JSONElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElement.JSONObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.JSONElement.JSONObject.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.JSONElement.JSONObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElement.JSONObject.displayName = 'proto.yorkie.v1.JSONElement.JSONObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElement.JSONArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.JSONElement.JSONArray.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.JSONElement.JSONArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElement.JSONArray.displayName = 'proto.yorkie.v1.JSONElement.JSONArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElement.Primitive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.JSONElement.Primitive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElement.Primitive.displayName = 'proto.yorkie.v1.JSONElement.Primitive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElement.Text = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.JSONElement.Text.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.JSONElement.Text, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElement.Text.displayName = 'proto.yorkie.v1.JSONElement.Text';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElement.RichText = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.JSONElement.RichText.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.JSONElement.RichText, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElement.RichText.displayName = 'proto.yorkie.v1.JSONElement.RichText';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.JSONElement.Counter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.JSONElement.Counter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.JSONElement.Counter.displayName = 'proto.yorkie.v1.JSONElement.Counter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.RHTNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.RHTNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.RHTNode.displayName = 'proto.yorkie.v1.RHTNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.RGANode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.RGANode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.RGANode.displayName = 'proto.yorkie.v1.RGANode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.TextNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.TextNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.TextNode.displayName = 'proto.yorkie.v1.TextNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.RichTextNodeAttr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.RichTextNodeAttr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.RichTextNodeAttr.displayName = 'proto.yorkie.v1.RichTextNodeAttr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.RichTextNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.RichTextNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.RichTextNode.displayName = 'proto.yorkie.v1.RichTextNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.TextNodeID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.TextNodeID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.TextNodeID.displayName = 'proto.yorkie.v1.TextNodeID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.User.displayName = 'proto.yorkie.v1.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Project = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.Project.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.Project, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Project.displayName = 'proto.yorkie.v1.Project';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.UpdatableProjectFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.UpdatableProjectFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.UpdatableProjectFields.displayName = 'proto.yorkie.v1.UpdatableProjectFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.displayName = 'proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.DocumentSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.DocumentSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.DocumentSummary.displayName = 'proto.yorkie.v1.DocumentSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Presence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Presence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Presence.displayName = 'proto.yorkie.v1.Presence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Client = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Client, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Client.displayName = 'proto.yorkie.v1.Client';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Clients = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.Clients.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.Clients, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Clients.displayName = 'proto.yorkie.v1.Clients';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.Checkpoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.Checkpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.Checkpoint.displayName = 'proto.yorkie.v1.Checkpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.TextNodePos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.TextNodePos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.TextNodePos.displayName = 'proto.yorkie.v1.TextNodePos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.TimeTicket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.TimeTicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.TimeTicket.displayName = 'proto.yorkie.v1.TimeTicket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.DocEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.DocEvent.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.DocEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.DocEvent.displayName = 'proto.yorkie.v1.DocEvent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.ChangePack.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.ChangePack.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.ChangePack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.ChangePack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ChangePack.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkpoint: (f = msg.getCheckpoint()) && proto.yorkie.v1.Checkpoint.toObject(includeInstance, f),
    snapshot: msg.getSnapshot_asB64(),
    changesList: jspb.Message.toObjectList(msg.getChangesList(),
    proto.yorkie.v1.Change.toObject, includeInstance),
    minSyncedTicket: (f = msg.getMinSyncedTicket()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.ChangePack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.ChangePack;
  return proto.yorkie.v1.ChangePack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.ChangePack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.ChangePack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentKey(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.Checkpoint;
      reader.readMessage(value,proto.yorkie.v1.Checkpoint.deserializeBinaryFromReader);
      msg.setCheckpoint(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSnapshot(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.Change;
      reader.readMessage(value,proto.yorkie.v1.Change.deserializeBinaryFromReader);
      msg.addChanges(value);
      break;
    case 5:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMinSyncedTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.ChangePack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.ChangePack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.ChangePack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ChangePack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckpoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.Checkpoint.serializeBinaryToWriter
    );
  }
  f = message.getSnapshot_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.yorkie.v1.Change.serializeBinaryToWriter
    );
  }
  f = message.getMinSyncedTicket();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional string document_key = 1;
 * @return {string}
 */
proto.yorkie.v1.ChangePack.prototype.getDocumentKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.ChangePack} returns this
 */
proto.yorkie.v1.ChangePack.prototype.setDocumentKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Checkpoint checkpoint = 2;
 * @return {?proto.yorkie.v1.Checkpoint}
 */
proto.yorkie.v1.ChangePack.prototype.getCheckpoint = function() {
  return /** @type{?proto.yorkie.v1.Checkpoint} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Checkpoint, 2));
};


/**
 * @param {?proto.yorkie.v1.Checkpoint|undefined} value
 * @return {!proto.yorkie.v1.ChangePack} returns this
*/
proto.yorkie.v1.ChangePack.prototype.setCheckpoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.ChangePack} returns this
 */
proto.yorkie.v1.ChangePack.prototype.clearCheckpoint = function() {
  return this.setCheckpoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.ChangePack.prototype.hasCheckpoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes snapshot = 3;
 * @return {string}
 */
proto.yorkie.v1.ChangePack.prototype.getSnapshot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes snapshot = 3;
 * This is a type-conversion wrapper around `getSnapshot()`
 * @return {string}
 */
proto.yorkie.v1.ChangePack.prototype.getSnapshot_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSnapshot()));
};


/**
 * optional bytes snapshot = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSnapshot()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.ChangePack.prototype.getSnapshot_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSnapshot()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.ChangePack} returns this
 */
proto.yorkie.v1.ChangePack.prototype.setSnapshot = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * repeated Change changes = 4;
 * @return {!Array<!proto.yorkie.v1.Change>}
 */
proto.yorkie.v1.ChangePack.prototype.getChangesList = function() {
  return /** @type{!Array<!proto.yorkie.v1.Change>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yorkie.v1.Change, 4));
};


/**
 * @param {!Array<!proto.yorkie.v1.Change>} value
 * @return {!proto.yorkie.v1.ChangePack} returns this
*/
proto.yorkie.v1.ChangePack.prototype.setChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.yorkie.v1.Change=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.Change}
 */
proto.yorkie.v1.ChangePack.prototype.addChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.yorkie.v1.Change, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.ChangePack} returns this
 */
proto.yorkie.v1.ChangePack.prototype.clearChangesList = function() {
  return this.setChangesList([]);
};


/**
 * optional TimeTicket min_synced_ticket = 5;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.ChangePack.prototype.getMinSyncedTicket = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 5));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.ChangePack} returns this
*/
proto.yorkie.v1.ChangePack.prototype.setMinSyncedTicket = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.ChangePack} returns this
 */
proto.yorkie.v1.ChangePack.prototype.clearMinSyncedTicket = function() {
  return this.setMinSyncedTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.ChangePack.prototype.hasMinSyncedTicket = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.Change.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Change.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Change.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Change} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Change.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.yorkie.v1.ChangeID.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.yorkie.v1.Operation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Change}
 */
proto.yorkie.v1.Change.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Change;
  return proto.yorkie.v1.Change.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Change} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Change}
 */
proto.yorkie.v1.Change.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.ChangeID;
      reader.readMessage(value,proto.yorkie.v1.ChangeID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.Operation;
      reader.readMessage(value,proto.yorkie.v1.Operation.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Change.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Change.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Change} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Change.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.ChangeID.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.yorkie.v1.Operation.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangeID id = 1;
 * @return {?proto.yorkie.v1.ChangeID}
 */
proto.yorkie.v1.Change.prototype.getId = function() {
  return /** @type{?proto.yorkie.v1.ChangeID} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.ChangeID, 1));
};


/**
 * @param {?proto.yorkie.v1.ChangeID|undefined} value
 * @return {!proto.yorkie.v1.Change} returns this
*/
proto.yorkie.v1.Change.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Change} returns this
 */
proto.yorkie.v1.Change.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Change.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.yorkie.v1.Change.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Change} returns this
 */
proto.yorkie.v1.Change.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Operation operations = 3;
 * @return {!Array<!proto.yorkie.v1.Operation>}
 */
proto.yorkie.v1.Change.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.yorkie.v1.Operation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yorkie.v1.Operation, 3));
};


/**
 * @param {!Array<!proto.yorkie.v1.Operation>} value
 * @return {!proto.yorkie.v1.Change} returns this
*/
proto.yorkie.v1.Change.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.yorkie.v1.Operation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.Operation}
 */
proto.yorkie.v1.Change.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.yorkie.v1.Operation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.Change} returns this
 */
proto.yorkie.v1.Change.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.ChangeID.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.ChangeID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.ChangeID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ChangeID.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientSeq: jspb.Message.getFieldWithDefault(msg, 1, 0),
    serverSeq: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    lamport: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    actorId: msg.getActorId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.ChangeID}
 */
proto.yorkie.v1.ChangeID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.ChangeID;
  return proto.yorkie.v1.ChangeID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.ChangeID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.ChangeID}
 */
proto.yorkie.v1.ChangeID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClientSeq(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setServerSeq(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLamport(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setActorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.ChangeID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.ChangeID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.ChangeID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ChangeID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientSeq();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getServerSeq();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getLamport();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getActorId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional uint32 client_seq = 1;
 * @return {number}
 */
proto.yorkie.v1.ChangeID.prototype.getClientSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.yorkie.v1.ChangeID} returns this
 */
proto.yorkie.v1.ChangeID.prototype.setClientSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 server_seq = 2;
 * @return {string}
 */
proto.yorkie.v1.ChangeID.prototype.getServerSeq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.ChangeID} returns this
 */
proto.yorkie.v1.ChangeID.prototype.setServerSeq = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 lamport = 3;
 * @return {string}
 */
proto.yorkie.v1.ChangeID.prototype.getLamport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.ChangeID} returns this
 */
proto.yorkie.v1.ChangeID.prototype.setLamport = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional bytes actor_id = 4;
 * @return {string}
 */
proto.yorkie.v1.ChangeID.prototype.getActorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes actor_id = 4;
 * This is a type-conversion wrapper around `getActorId()`
 * @return {string}
 */
proto.yorkie.v1.ChangeID.prototype.getActorId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getActorId()));
};


/**
 * optional bytes actor_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getActorId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.ChangeID.prototype.getActorId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getActorId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.ChangeID} returns this
 */
proto.yorkie.v1.ChangeID.prototype.setActorId = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.yorkie.v1.Operation.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.yorkie.v1.Operation.BodyCase = {
  BODY_NOT_SET: 0,
  SET: 1,
  ADD: 2,
  MOVE: 3,
  REMOVE: 4,
  EDIT: 5,
  SELECT: 6,
  RICH_EDIT: 7,
  STYLE: 8,
  INCREASE: 9
};

/**
 * @return {proto.yorkie.v1.Operation.BodyCase}
 */
proto.yorkie.v1.Operation.prototype.getBodyCase = function() {
  return /** @type {proto.yorkie.v1.Operation.BodyCase} */(jspb.Message.computeOneofCase(this, proto.yorkie.v1.Operation.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.toObject = function(includeInstance, msg) {
  var f, obj = {
    set: (f = msg.getSet()) && proto.yorkie.v1.Operation.Set.toObject(includeInstance, f),
    add: (f = msg.getAdd()) && proto.yorkie.v1.Operation.Add.toObject(includeInstance, f),
    move: (f = msg.getMove()) && proto.yorkie.v1.Operation.Move.toObject(includeInstance, f),
    remove: (f = msg.getRemove()) && proto.yorkie.v1.Operation.Remove.toObject(includeInstance, f),
    edit: (f = msg.getEdit()) && proto.yorkie.v1.Operation.Edit.toObject(includeInstance, f),
    select: (f = msg.getSelect()) && proto.yorkie.v1.Operation.Select.toObject(includeInstance, f),
    richEdit: (f = msg.getRichEdit()) && proto.yorkie.v1.Operation.RichEdit.toObject(includeInstance, f),
    style: (f = msg.getStyle()) && proto.yorkie.v1.Operation.Style.toObject(includeInstance, f),
    increase: (f = msg.getIncrease()) && proto.yorkie.v1.Operation.Increase.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation}
 */
proto.yorkie.v1.Operation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation;
  return proto.yorkie.v1.Operation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation}
 */
proto.yorkie.v1.Operation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.Operation.Set;
      reader.readMessage(value,proto.yorkie.v1.Operation.Set.deserializeBinaryFromReader);
      msg.setSet(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.Operation.Add;
      reader.readMessage(value,proto.yorkie.v1.Operation.Add.deserializeBinaryFromReader);
      msg.setAdd(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.Operation.Move;
      reader.readMessage(value,proto.yorkie.v1.Operation.Move.deserializeBinaryFromReader);
      msg.setMove(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.Operation.Remove;
      reader.readMessage(value,proto.yorkie.v1.Operation.Remove.deserializeBinaryFromReader);
      msg.setRemove(value);
      break;
    case 5:
      var value = new proto.yorkie.v1.Operation.Edit;
      reader.readMessage(value,proto.yorkie.v1.Operation.Edit.deserializeBinaryFromReader);
      msg.setEdit(value);
      break;
    case 6:
      var value = new proto.yorkie.v1.Operation.Select;
      reader.readMessage(value,proto.yorkie.v1.Operation.Select.deserializeBinaryFromReader);
      msg.setSelect(value);
      break;
    case 7:
      var value = new proto.yorkie.v1.Operation.RichEdit;
      reader.readMessage(value,proto.yorkie.v1.Operation.RichEdit.deserializeBinaryFromReader);
      msg.setRichEdit(value);
      break;
    case 8:
      var value = new proto.yorkie.v1.Operation.Style;
      reader.readMessage(value,proto.yorkie.v1.Operation.Style.deserializeBinaryFromReader);
      msg.setStyle(value);
      break;
    case 9:
      var value = new proto.yorkie.v1.Operation.Increase;
      reader.readMessage(value,proto.yorkie.v1.Operation.Increase.deserializeBinaryFromReader);
      msg.setIncrease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.Operation.Set.serializeBinaryToWriter
    );
  }
  f = message.getAdd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.Operation.Add.serializeBinaryToWriter
    );
  }
  f = message.getMove();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.Operation.Move.serializeBinaryToWriter
    );
  }
  f = message.getRemove();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.Operation.Remove.serializeBinaryToWriter
    );
  }
  f = message.getEdit();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.yorkie.v1.Operation.Edit.serializeBinaryToWriter
    );
  }
  f = message.getSelect();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.yorkie.v1.Operation.Select.serializeBinaryToWriter
    );
  }
  f = message.getRichEdit();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.yorkie.v1.Operation.RichEdit.serializeBinaryToWriter
    );
  }
  f = message.getStyle();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.yorkie.v1.Operation.Style.serializeBinaryToWriter
    );
  }
  f = message.getIncrease();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.yorkie.v1.Operation.Increase.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Set.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Set.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Set} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Set.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && proto.yorkie.v1.JSONElementSimple.toObject(includeInstance, f),
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Set}
 */
proto.yorkie.v1.Operation.Set.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Set;
  return proto.yorkie.v1.Operation.Set.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Set} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Set}
 */
proto.yorkie.v1.Operation.Set.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.JSONElementSimple;
      reader.readMessage(value,proto.yorkie.v1.JSONElementSimple.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Set.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Set.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Set} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Set.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.JSONElementSimple.serializeBinaryToWriter
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Set.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Set} returns this
*/
proto.yorkie.v1.Operation.Set.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Set} returns this
 */
proto.yorkie.v1.Operation.Set.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Set.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.yorkie.v1.Operation.Set.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Operation.Set} returns this
 */
proto.yorkie.v1.Operation.Set.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional JSONElementSimple value = 3;
 * @return {?proto.yorkie.v1.JSONElementSimple}
 */
proto.yorkie.v1.Operation.Set.prototype.getValue = function() {
  return /** @type{?proto.yorkie.v1.JSONElementSimple} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElementSimple, 3));
};


/**
 * @param {?proto.yorkie.v1.JSONElementSimple|undefined} value
 * @return {!proto.yorkie.v1.Operation.Set} returns this
*/
proto.yorkie.v1.Operation.Set.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Set} returns this
 */
proto.yorkie.v1.Operation.Set.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Set.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket executed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Set.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Set} returns this
*/
proto.yorkie.v1.Operation.Set.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Set} returns this
 */
proto.yorkie.v1.Operation.Set.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Set.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Add.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Add.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Add} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Add.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    prevCreatedAt: (f = msg.getPrevCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    value: (f = msg.getValue()) && proto.yorkie.v1.JSONElementSimple.toObject(includeInstance, f),
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Add}
 */
proto.yorkie.v1.Operation.Add.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Add;
  return proto.yorkie.v1.Operation.Add.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Add} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Add}
 */
proto.yorkie.v1.Operation.Add.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setPrevCreatedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.JSONElementSimple;
      reader.readMessage(value,proto.yorkie.v1.JSONElementSimple.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Add.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Add.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Add} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Add.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getPrevCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.JSONElementSimple.serializeBinaryToWriter
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Add.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Add} returns this
*/
proto.yorkie.v1.Operation.Add.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Add} returns this
 */
proto.yorkie.v1.Operation.Add.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Add.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimeTicket prev_created_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Add.prototype.getPrevCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Add} returns this
*/
proto.yorkie.v1.Operation.Add.prototype.setPrevCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Add} returns this
 */
proto.yorkie.v1.Operation.Add.prototype.clearPrevCreatedAt = function() {
  return this.setPrevCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Add.prototype.hasPrevCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional JSONElementSimple value = 3;
 * @return {?proto.yorkie.v1.JSONElementSimple}
 */
proto.yorkie.v1.Operation.Add.prototype.getValue = function() {
  return /** @type{?proto.yorkie.v1.JSONElementSimple} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElementSimple, 3));
};


/**
 * @param {?proto.yorkie.v1.JSONElementSimple|undefined} value
 * @return {!proto.yorkie.v1.Operation.Add} returns this
*/
proto.yorkie.v1.Operation.Add.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Add} returns this
 */
proto.yorkie.v1.Operation.Add.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Add.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket executed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Add.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Add} returns this
*/
proto.yorkie.v1.Operation.Add.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Add} returns this
 */
proto.yorkie.v1.Operation.Add.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Add.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Move.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Move.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Move} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Move.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    prevCreatedAt: (f = msg.getPrevCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Move}
 */
proto.yorkie.v1.Operation.Move.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Move;
  return proto.yorkie.v1.Operation.Move.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Move} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Move}
 */
proto.yorkie.v1.Operation.Move.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setPrevCreatedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Move.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Move.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Move} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Move.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getPrevCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Move.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Move} returns this
*/
proto.yorkie.v1.Operation.Move.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Move} returns this
 */
proto.yorkie.v1.Operation.Move.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Move.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimeTicket prev_created_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Move.prototype.getPrevCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Move} returns this
*/
proto.yorkie.v1.Operation.Move.prototype.setPrevCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Move} returns this
 */
proto.yorkie.v1.Operation.Move.prototype.clearPrevCreatedAt = function() {
  return this.setPrevCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Move.prototype.hasPrevCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket created_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Move.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Move} returns this
*/
proto.yorkie.v1.Operation.Move.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Move} returns this
 */
proto.yorkie.v1.Operation.Move.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Move.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket executed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Move.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Move} returns this
*/
proto.yorkie.v1.Operation.Move.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Move} returns this
 */
proto.yorkie.v1.Operation.Move.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Move.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Remove.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Remove.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Remove} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Remove.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Remove}
 */
proto.yorkie.v1.Operation.Remove.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Remove;
  return proto.yorkie.v1.Operation.Remove.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Remove} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Remove}
 */
proto.yorkie.v1.Operation.Remove.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Remove.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Remove.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Remove} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Remove.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Remove.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Remove} returns this
*/
proto.yorkie.v1.Operation.Remove.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Remove} returns this
 */
proto.yorkie.v1.Operation.Remove.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Remove.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimeTicket created_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Remove.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Remove} returns this
*/
proto.yorkie.v1.Operation.Remove.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Remove} returns this
 */
proto.yorkie.v1.Operation.Remove.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Remove.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket executed_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Remove.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Remove} returns this
*/
proto.yorkie.v1.Operation.Remove.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Remove} returns this
 */
proto.yorkie.v1.Operation.Remove.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Remove.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Edit.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Edit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Edit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Edit.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    createdAtMapByActorMap: (f = msg.getCreatedAtMapByActorMap()) ? f.toObject(includeInstance, proto.yorkie.v1.TimeTicket.toObject) : [],
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Edit}
 */
proto.yorkie.v1.Operation.Edit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Edit;
  return proto.yorkie.v1.Operation.Edit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Edit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Edit}
 */
proto.yorkie.v1.Operation.Edit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = msg.getCreatedAtMapByActorMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader, "", new proto.yorkie.v1.TimeTicket());
         });
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Edit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Edit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Edit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Edit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAtMapByActorMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.yorkie.v1.TimeTicket.serializeBinaryToWriter);
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Edit.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
*/
proto.yorkie.v1.Operation.Edit.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
 */
proto.yorkie.v1.Operation.Edit.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Edit.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextNodePos from = 2;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.Edit.prototype.getFrom = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 2));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
*/
proto.yorkie.v1.Operation.Edit.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
 */
proto.yorkie.v1.Operation.Edit.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Edit.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TextNodePos to = 3;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.Edit.prototype.getTo = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 3));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
*/
proto.yorkie.v1.Operation.Edit.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
 */
proto.yorkie.v1.Operation.Edit.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Edit.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, TimeTicket> created_at_map_by_actor = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.yorkie.v1.TimeTicket>}
 */
proto.yorkie.v1.Operation.Edit.prototype.getCreatedAtMapByActorMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.yorkie.v1.TimeTicket>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.yorkie.v1.TimeTicket));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
 */
proto.yorkie.v1.Operation.Edit.prototype.clearCreatedAtMapByActorMap = function() {
  this.getCreatedAtMapByActorMap().clear();
  return this;};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.yorkie.v1.Operation.Edit.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
 */
proto.yorkie.v1.Operation.Edit.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TimeTicket executed_at = 6;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Edit.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 6));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
*/
proto.yorkie.v1.Operation.Edit.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Edit} returns this
 */
proto.yorkie.v1.Operation.Edit.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Edit.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Select.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Select.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Select} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Select.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Select}
 */
proto.yorkie.v1.Operation.Select.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Select;
  return proto.yorkie.v1.Operation.Select.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Select} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Select}
 */
proto.yorkie.v1.Operation.Select.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Select.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Select.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Select} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Select.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Select.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Select} returns this
*/
proto.yorkie.v1.Operation.Select.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Select} returns this
 */
proto.yorkie.v1.Operation.Select.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Select.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextNodePos from = 2;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.Select.prototype.getFrom = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 2));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.Select} returns this
*/
proto.yorkie.v1.Operation.Select.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Select} returns this
 */
proto.yorkie.v1.Operation.Select.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Select.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TextNodePos to = 3;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.Select.prototype.getTo = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 3));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.Select} returns this
*/
proto.yorkie.v1.Operation.Select.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Select} returns this
 */
proto.yorkie.v1.Operation.Select.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Select.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket executed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Select.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Select} returns this
*/
proto.yorkie.v1.Operation.Select.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Select} returns this
 */
proto.yorkie.v1.Operation.Select.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Select.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.RichEdit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.RichEdit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.RichEdit.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    createdAtMapByActorMap: (f = msg.getCreatedAtMapByActorMap()) ? f.toObject(includeInstance, proto.yorkie.v1.TimeTicket.toObject) : [],
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, undefined) : [],
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.RichEdit}
 */
proto.yorkie.v1.Operation.RichEdit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.RichEdit;
  return proto.yorkie.v1.Operation.RichEdit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.RichEdit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.RichEdit}
 */
proto.yorkie.v1.Operation.RichEdit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = msg.getCreatedAtMapByActorMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader, "", new proto.yorkie.v1.TimeTicket());
         });
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.RichEdit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.RichEdit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.RichEdit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAtMapByActorMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.yorkie.v1.TimeTicket.serializeBinaryToWriter);
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
*/
proto.yorkie.v1.Operation.RichEdit.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
 */
proto.yorkie.v1.Operation.RichEdit.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextNodePos from = 2;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.getFrom = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 2));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
*/
proto.yorkie.v1.Operation.RichEdit.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
 */
proto.yorkie.v1.Operation.RichEdit.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TextNodePos to = 3;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.getTo = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 3));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
*/
proto.yorkie.v1.Operation.RichEdit.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
 */
proto.yorkie.v1.Operation.RichEdit.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, TimeTicket> created_at_map_by_actor = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.yorkie.v1.TimeTicket>}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.getCreatedAtMapByActorMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.yorkie.v1.TimeTicket>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.yorkie.v1.TimeTicket));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
 */
proto.yorkie.v1.Operation.RichEdit.prototype.clearCreatedAtMapByActorMap = function() {
  this.getCreatedAtMapByActorMap().clear();
  return this;};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
 */
proto.yorkie.v1.Operation.RichEdit.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * map<string, string> attributes = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
 */
proto.yorkie.v1.Operation.RichEdit.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;};


/**
 * optional TimeTicket executed_at = 7;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 7));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
*/
proto.yorkie.v1.Operation.RichEdit.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.RichEdit} returns this
 */
proto.yorkie.v1.Operation.RichEdit.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.RichEdit.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Style.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Style.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Style} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Style.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.yorkie.v1.TextNodePos.toObject(includeInstance, f),
    attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, undefined) : [],
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Style}
 */
proto.yorkie.v1.Operation.Style.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Style;
  return proto.yorkie.v1.Operation.Style.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Style} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Style}
 */
proto.yorkie.v1.Operation.Style.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TextNodePos;
      reader.readMessage(value,proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Style.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Style.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Style} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Style.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TextNodePos.serializeBinaryToWriter
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Style.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Style} returns this
*/
proto.yorkie.v1.Operation.Style.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Style} returns this
 */
proto.yorkie.v1.Operation.Style.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Style.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextNodePos from = 2;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.Style.prototype.getFrom = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 2));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.Style} returns this
*/
proto.yorkie.v1.Operation.Style.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Style} returns this
 */
proto.yorkie.v1.Operation.Style.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Style.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TextNodePos to = 3;
 * @return {?proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.Operation.Style.prototype.getTo = function() {
  return /** @type{?proto.yorkie.v1.TextNodePos} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodePos, 3));
};


/**
 * @param {?proto.yorkie.v1.TextNodePos|undefined} value
 * @return {!proto.yorkie.v1.Operation.Style} returns this
*/
proto.yorkie.v1.Operation.Style.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Style} returns this
 */
proto.yorkie.v1.Operation.Style.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Style.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, string> attributes = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yorkie.v1.Operation.Style.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yorkie.v1.Operation.Style} returns this
 */
proto.yorkie.v1.Operation.Style.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;};


/**
 * optional TimeTicket executed_at = 5;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Style.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 5));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Style} returns this
*/
proto.yorkie.v1.Operation.Style.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Style} returns this
 */
proto.yorkie.v1.Operation.Style.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Style.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Operation.Increase.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Operation.Increase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Operation.Increase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Increase.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentCreatedAt: (f = msg.getParentCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    value: (f = msg.getValue()) && proto.yorkie.v1.JSONElementSimple.toObject(includeInstance, f),
    executedAt: (f = msg.getExecutedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Operation.Increase}
 */
proto.yorkie.v1.Operation.Increase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Operation.Increase;
  return proto.yorkie.v1.Operation.Increase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Operation.Increase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Operation.Increase}
 */
proto.yorkie.v1.Operation.Increase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setParentCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.JSONElementSimple;
      reader.readMessage(value,proto.yorkie.v1.JSONElementSimple.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setExecutedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Operation.Increase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Operation.Increase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Operation.Increase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Operation.Increase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.JSONElementSimple.serializeBinaryToWriter
    );
  }
  f = message.getExecutedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeTicket parent_created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Increase.prototype.getParentCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Increase} returns this
*/
proto.yorkie.v1.Operation.Increase.prototype.setParentCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Increase} returns this
 */
proto.yorkie.v1.Operation.Increase.prototype.clearParentCreatedAt = function() {
  return this.setParentCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Increase.prototype.hasParentCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional JSONElementSimple value = 2;
 * @return {?proto.yorkie.v1.JSONElementSimple}
 */
proto.yorkie.v1.Operation.Increase.prototype.getValue = function() {
  return /** @type{?proto.yorkie.v1.JSONElementSimple} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElementSimple, 2));
};


/**
 * @param {?proto.yorkie.v1.JSONElementSimple|undefined} value
 * @return {!proto.yorkie.v1.Operation.Increase} returns this
*/
proto.yorkie.v1.Operation.Increase.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Increase} returns this
 */
proto.yorkie.v1.Operation.Increase.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Increase.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket executed_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.Operation.Increase.prototype.getExecutedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.Operation.Increase} returns this
*/
proto.yorkie.v1.Operation.Increase.prototype.setExecutedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation.Increase} returns this
 */
proto.yorkie.v1.Operation.Increase.prototype.clearExecutedAt = function() {
  return this.setExecutedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.Increase.prototype.hasExecutedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Set set = 1;
 * @return {?proto.yorkie.v1.Operation.Set}
 */
proto.yorkie.v1.Operation.prototype.getSet = function() {
  return /** @type{?proto.yorkie.v1.Operation.Set} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Set, 1));
};


/**
 * @param {?proto.yorkie.v1.Operation.Set|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearSet = function() {
  return this.setSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasSet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Add add = 2;
 * @return {?proto.yorkie.v1.Operation.Add}
 */
proto.yorkie.v1.Operation.prototype.getAdd = function() {
  return /** @type{?proto.yorkie.v1.Operation.Add} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Add, 2));
};


/**
 * @param {?proto.yorkie.v1.Operation.Add|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setAdd = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearAdd = function() {
  return this.setAdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasAdd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Move move = 3;
 * @return {?proto.yorkie.v1.Operation.Move}
 */
proto.yorkie.v1.Operation.prototype.getMove = function() {
  return /** @type{?proto.yorkie.v1.Operation.Move} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Move, 3));
};


/**
 * @param {?proto.yorkie.v1.Operation.Move|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setMove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearMove = function() {
  return this.setMove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasMove = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Remove remove = 4;
 * @return {?proto.yorkie.v1.Operation.Remove}
 */
proto.yorkie.v1.Operation.prototype.getRemove = function() {
  return /** @type{?proto.yorkie.v1.Operation.Remove} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Remove, 4));
};


/**
 * @param {?proto.yorkie.v1.Operation.Remove|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setRemove = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearRemove = function() {
  return this.setRemove(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasRemove = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Edit edit = 5;
 * @return {?proto.yorkie.v1.Operation.Edit}
 */
proto.yorkie.v1.Operation.prototype.getEdit = function() {
  return /** @type{?proto.yorkie.v1.Operation.Edit} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Edit, 5));
};


/**
 * @param {?proto.yorkie.v1.Operation.Edit|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setEdit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearEdit = function() {
  return this.setEdit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasEdit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Select select = 6;
 * @return {?proto.yorkie.v1.Operation.Select}
 */
proto.yorkie.v1.Operation.prototype.getSelect = function() {
  return /** @type{?proto.yorkie.v1.Operation.Select} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Select, 6));
};


/**
 * @param {?proto.yorkie.v1.Operation.Select|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setSelect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearSelect = function() {
  return this.setSelect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasSelect = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RichEdit rich_edit = 7;
 * @return {?proto.yorkie.v1.Operation.RichEdit}
 */
proto.yorkie.v1.Operation.prototype.getRichEdit = function() {
  return /** @type{?proto.yorkie.v1.Operation.RichEdit} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.RichEdit, 7));
};


/**
 * @param {?proto.yorkie.v1.Operation.RichEdit|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setRichEdit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearRichEdit = function() {
  return this.setRichEdit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasRichEdit = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Style style = 8;
 * @return {?proto.yorkie.v1.Operation.Style}
 */
proto.yorkie.v1.Operation.prototype.getStyle = function() {
  return /** @type{?proto.yorkie.v1.Operation.Style} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Style, 8));
};


/**
 * @param {?proto.yorkie.v1.Operation.Style|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setStyle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearStyle = function() {
  return this.setStyle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasStyle = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Increase increase = 9;
 * @return {?proto.yorkie.v1.Operation.Increase}
 */
proto.yorkie.v1.Operation.prototype.getIncrease = function() {
  return /** @type{?proto.yorkie.v1.Operation.Increase} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Operation.Increase, 9));
};


/**
 * @param {?proto.yorkie.v1.Operation.Increase|undefined} value
 * @return {!proto.yorkie.v1.Operation} returns this
*/
proto.yorkie.v1.Operation.prototype.setIncrease = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.yorkie.v1.Operation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Operation} returns this
 */
proto.yorkie.v1.Operation.prototype.clearIncrease = function() {
  return this.setIncrease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Operation.prototype.hasIncrease = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElementSimple.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElementSimple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElementSimple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElementSimple.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    movedAt: (f = msg.getMovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElementSimple}
 */
proto.yorkie.v1.JSONElementSimple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElementSimple;
  return proto.yorkie.v1.JSONElementSimple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElementSimple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElementSimple}
 */
proto.yorkie.v1.JSONElementSimple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMovedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    case 4:
      var value = /** @type {!proto.yorkie.v1.ValueType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElementSimple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElementSimple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElementSimple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElementSimple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getMovedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional TimeTicket created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElementSimple.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
*/
proto.yorkie.v1.JSONElementSimple.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
 */
proto.yorkie.v1.JSONElementSimple.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElementSimple.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimeTicket moved_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElementSimple.prototype.getMovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
*/
proto.yorkie.v1.JSONElementSimple.prototype.setMovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
 */
proto.yorkie.v1.JSONElementSimple.prototype.clearMovedAt = function() {
  return this.setMovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElementSimple.prototype.hasMovedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket removed_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElementSimple.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
*/
proto.yorkie.v1.JSONElementSimple.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
 */
proto.yorkie.v1.JSONElementSimple.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElementSimple.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValueType type = 4;
 * @return {!proto.yorkie.v1.ValueType}
 */
proto.yorkie.v1.JSONElementSimple.prototype.getType = function() {
  return /** @type {!proto.yorkie.v1.ValueType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.yorkie.v1.ValueType} value
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
 */
proto.yorkie.v1.JSONElementSimple.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bytes value = 5;
 * @return {string}
 */
proto.yorkie.v1.JSONElementSimple.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes value = 5;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.yorkie.v1.JSONElementSimple.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElementSimple.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.JSONElementSimple} returns this
 */
proto.yorkie.v1.JSONElementSimple.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.yorkie.v1.JSONElement.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.yorkie.v1.JSONElement.BodyCase = {
  BODY_NOT_SET: 0,
  JSON_OBJECT: 1,
  JSON_ARRAY: 2,
  PRIMITIVE: 3,
  TEXT: 4,
  RICH_TEXT: 5,
  COUNTER: 6
};

/**
 * @return {proto.yorkie.v1.JSONElement.BodyCase}
 */
proto.yorkie.v1.JSONElement.prototype.getBodyCase = function() {
  return /** @type {proto.yorkie.v1.JSONElement.BodyCase} */(jspb.Message.computeOneofCase(this, proto.yorkie.v1.JSONElement.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElement.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    jsonObject: (f = msg.getJsonObject()) && proto.yorkie.v1.JSONElement.JSONObject.toObject(includeInstance, f),
    jsonArray: (f = msg.getJsonArray()) && proto.yorkie.v1.JSONElement.JSONArray.toObject(includeInstance, f),
    primitive: (f = msg.getPrimitive()) && proto.yorkie.v1.JSONElement.Primitive.toObject(includeInstance, f),
    text: (f = msg.getText()) && proto.yorkie.v1.JSONElement.Text.toObject(includeInstance, f),
    richText: (f = msg.getRichText()) && proto.yorkie.v1.JSONElement.RichText.toObject(includeInstance, f),
    counter: (f = msg.getCounter()) && proto.yorkie.v1.JSONElement.Counter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElement}
 */
proto.yorkie.v1.JSONElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElement;
  return proto.yorkie.v1.JSONElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElement}
 */
proto.yorkie.v1.JSONElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.JSONElement.JSONObject;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.JSONObject.deserializeBinaryFromReader);
      msg.setJsonObject(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.JSONElement.JSONArray;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.JSONArray.deserializeBinaryFromReader);
      msg.setJsonArray(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.JSONElement.Primitive;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.Primitive.deserializeBinaryFromReader);
      msg.setPrimitive(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.JSONElement.Text;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.Text.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    case 5:
      var value = new proto.yorkie.v1.JSONElement.RichText;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.RichText.deserializeBinaryFromReader);
      msg.setRichText(value);
      break;
    case 6:
      var value = new proto.yorkie.v1.JSONElement.Counter;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.Counter.deserializeBinaryFromReader);
      msg.setCounter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJsonObject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.JSONElement.JSONObject.serializeBinaryToWriter
    );
  }
  f = message.getJsonArray();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.JSONElement.JSONArray.serializeBinaryToWriter
    );
  }
  f = message.getPrimitive();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.JSONElement.Primitive.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.JSONElement.Text.serializeBinaryToWriter
    );
  }
  f = message.getRichText();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.yorkie.v1.JSONElement.RichText.serializeBinaryToWriter
    );
  }
  f = message.getCounter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.yorkie.v1.JSONElement.Counter.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.JSONElement.JSONObject.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElement.JSONObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElement.JSONObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.JSONObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.yorkie.v1.RHTNode.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    movedAt: (f = msg.getMovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElement.JSONObject}
 */
proto.yorkie.v1.JSONElement.JSONObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElement.JSONObject;
  return proto.yorkie.v1.JSONElement.JSONObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElement.JSONObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElement.JSONObject}
 */
proto.yorkie.v1.JSONElement.JSONObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.RHTNode;
      reader.readMessage(value,proto.yorkie.v1.RHTNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMovedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElement.JSONObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElement.JSONObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.JSONObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.yorkie.v1.RHTNode.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getMovedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RHTNode nodes = 1;
 * @return {!Array<!proto.yorkie.v1.RHTNode>}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.yorkie.v1.RHTNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yorkie.v1.RHTNode, 1));
};


/**
 * @param {!Array<!proto.yorkie.v1.RHTNode>} value
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
*/
proto.yorkie.v1.JSONElement.JSONObject.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.yorkie.v1.RHTNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.RHTNode}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.yorkie.v1.RHTNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional TimeTicket created_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
*/
proto.yorkie.v1.JSONElement.JSONObject.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket moved_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.getMovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
*/
proto.yorkie.v1.JSONElement.JSONObject.prototype.setMovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.clearMovedAt = function() {
  return this.setMovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.hasMovedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket removed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
*/
proto.yorkie.v1.JSONElement.JSONObject.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.JSONObject} returns this
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.JSONObject.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.JSONElement.JSONArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElement.JSONArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElement.JSONArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.JSONArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.yorkie.v1.RGANode.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    movedAt: (f = msg.getMovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElement.JSONArray}
 */
proto.yorkie.v1.JSONElement.JSONArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElement.JSONArray;
  return proto.yorkie.v1.JSONElement.JSONArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElement.JSONArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElement.JSONArray}
 */
proto.yorkie.v1.JSONElement.JSONArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.RGANode;
      reader.readMessage(value,proto.yorkie.v1.RGANode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMovedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElement.JSONArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElement.JSONArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.JSONArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.yorkie.v1.RGANode.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getMovedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RGANode nodes = 1;
 * @return {!Array<!proto.yorkie.v1.RGANode>}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.yorkie.v1.RGANode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yorkie.v1.RGANode, 1));
};


/**
 * @param {!Array<!proto.yorkie.v1.RGANode>} value
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
*/
proto.yorkie.v1.JSONElement.JSONArray.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.yorkie.v1.RGANode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.RGANode}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.yorkie.v1.RGANode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional TimeTicket created_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
*/
proto.yorkie.v1.JSONElement.JSONArray.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket moved_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.getMovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
*/
proto.yorkie.v1.JSONElement.JSONArray.prototype.setMovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.clearMovedAt = function() {
  return this.setMovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.hasMovedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket removed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
*/
proto.yorkie.v1.JSONElement.JSONArray.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.JSONArray} returns this
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.JSONArray.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElement.Primitive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElement.Primitive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.Primitive.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: msg.getValue_asB64(),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    movedAt: (f = msg.getMovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElement.Primitive}
 */
proto.yorkie.v1.JSONElement.Primitive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElement.Primitive;
  return proto.yorkie.v1.JSONElement.Primitive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElement.Primitive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElement.Primitive}
 */
proto.yorkie.v1.JSONElement.Primitive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.yorkie.v1.ValueType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMovedAt(value);
      break;
    case 5:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElement.Primitive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElement.Primitive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.Primitive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getMovedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional ValueType type = 1;
 * @return {!proto.yorkie.v1.ValueType}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.getType = function() {
  return /** @type {!proto.yorkie.v1.ValueType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.yorkie.v1.ValueType} value
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {string}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional TimeTicket created_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
*/
proto.yorkie.v1.JSONElement.Primitive.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket moved_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.getMovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
*/
proto.yorkie.v1.JSONElement.Primitive.prototype.setMovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.clearMovedAt = function() {
  return this.setMovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.hasMovedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TimeTicket removed_at = 5;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 5));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
*/
proto.yorkie.v1.JSONElement.Primitive.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Primitive} returns this
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Primitive.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.JSONElement.Text.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElement.Text.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElement.Text.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElement.Text} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.Text.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.yorkie.v1.TextNode.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    movedAt: (f = msg.getMovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElement.Text}
 */
proto.yorkie.v1.JSONElement.Text.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElement.Text;
  return proto.yorkie.v1.JSONElement.Text.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElement.Text} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElement.Text}
 */
proto.yorkie.v1.JSONElement.Text.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TextNode;
      reader.readMessage(value,proto.yorkie.v1.TextNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMovedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.Text.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElement.Text.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElement.Text} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.Text.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.yorkie.v1.TextNode.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getMovedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TextNode nodes = 1;
 * @return {!Array<!proto.yorkie.v1.TextNode>}
 */
proto.yorkie.v1.JSONElement.Text.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.yorkie.v1.TextNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yorkie.v1.TextNode, 1));
};


/**
 * @param {!Array<!proto.yorkie.v1.TextNode>} value
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
*/
proto.yorkie.v1.JSONElement.Text.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.yorkie.v1.TextNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.TextNode}
 */
proto.yorkie.v1.JSONElement.Text.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.yorkie.v1.TextNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
 */
proto.yorkie.v1.JSONElement.Text.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional TimeTicket created_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Text.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
*/
proto.yorkie.v1.JSONElement.Text.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
 */
proto.yorkie.v1.JSONElement.Text.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Text.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket moved_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Text.prototype.getMovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
*/
proto.yorkie.v1.JSONElement.Text.prototype.setMovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
 */
proto.yorkie.v1.JSONElement.Text.prototype.clearMovedAt = function() {
  return this.setMovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Text.prototype.hasMovedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket removed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Text.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
*/
proto.yorkie.v1.JSONElement.Text.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Text} returns this
 */
proto.yorkie.v1.JSONElement.Text.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Text.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.JSONElement.RichText.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElement.RichText.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElement.RichText} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.RichText.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.yorkie.v1.RichTextNode.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    movedAt: (f = msg.getMovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElement.RichText}
 */
proto.yorkie.v1.JSONElement.RichText.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElement.RichText;
  return proto.yorkie.v1.JSONElement.RichText.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElement.RichText} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElement.RichText}
 */
proto.yorkie.v1.JSONElement.RichText.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.RichTextNode;
      reader.readMessage(value,proto.yorkie.v1.RichTextNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMovedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElement.RichText.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElement.RichText} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.RichText.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.yorkie.v1.RichTextNode.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getMovedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RichTextNode nodes = 1;
 * @return {!Array<!proto.yorkie.v1.RichTextNode>}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.yorkie.v1.RichTextNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yorkie.v1.RichTextNode, 1));
};


/**
 * @param {!Array<!proto.yorkie.v1.RichTextNode>} value
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
*/
proto.yorkie.v1.JSONElement.RichText.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.yorkie.v1.RichTextNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.RichTextNode}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.yorkie.v1.RichTextNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
 */
proto.yorkie.v1.JSONElement.RichText.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional TimeTicket created_at = 2;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 2));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
*/
proto.yorkie.v1.JSONElement.RichText.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
 */
proto.yorkie.v1.JSONElement.RichText.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeTicket moved_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.getMovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
*/
proto.yorkie.v1.JSONElement.RichText.prototype.setMovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
 */
proto.yorkie.v1.JSONElement.RichText.prototype.clearMovedAt = function() {
  return this.setMovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.hasMovedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket removed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
*/
proto.yorkie.v1.JSONElement.RichText.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.RichText} returns this
 */
proto.yorkie.v1.JSONElement.RichText.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.RichText.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.JSONElement.Counter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.JSONElement.Counter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.Counter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: msg.getValue_asB64(),
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    movedAt: (f = msg.getMovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.JSONElement.Counter}
 */
proto.yorkie.v1.JSONElement.Counter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.JSONElement.Counter;
  return proto.yorkie.v1.JSONElement.Counter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.JSONElement.Counter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.JSONElement.Counter}
 */
proto.yorkie.v1.JSONElement.Counter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.yorkie.v1.ValueType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setMovedAt(value);
      break;
    case 5:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.JSONElement.Counter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.JSONElement.Counter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.JSONElement.Counter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getMovedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional ValueType type = 1;
 * @return {!proto.yorkie.v1.ValueType}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.getType = function() {
  return /** @type {!proto.yorkie.v1.ValueType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.yorkie.v1.ValueType} value
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
 */
proto.yorkie.v1.JSONElement.Counter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {string}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
 */
proto.yorkie.v1.JSONElement.Counter.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional TimeTicket created_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
*/
proto.yorkie.v1.JSONElement.Counter.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
 */
proto.yorkie.v1.JSONElement.Counter.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TimeTicket moved_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.getMovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
*/
proto.yorkie.v1.JSONElement.Counter.prototype.setMovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
 */
proto.yorkie.v1.JSONElement.Counter.prototype.clearMovedAt = function() {
  return this.setMovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.hasMovedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TimeTicket removed_at = 5;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 5));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
*/
proto.yorkie.v1.JSONElement.Counter.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement.Counter} returns this
 */
proto.yorkie.v1.JSONElement.Counter.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.Counter.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional JSONObject json_object = 1;
 * @return {?proto.yorkie.v1.JSONElement.JSONObject}
 */
proto.yorkie.v1.JSONElement.prototype.getJsonObject = function() {
  return /** @type{?proto.yorkie.v1.JSONElement.JSONObject} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement.JSONObject, 1));
};


/**
 * @param {?proto.yorkie.v1.JSONElement.JSONObject|undefined} value
 * @return {!proto.yorkie.v1.JSONElement} returns this
*/
proto.yorkie.v1.JSONElement.prototype.setJsonObject = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.yorkie.v1.JSONElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement} returns this
 */
proto.yorkie.v1.JSONElement.prototype.clearJsonObject = function() {
  return this.setJsonObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.prototype.hasJsonObject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional JSONArray json_array = 2;
 * @return {?proto.yorkie.v1.JSONElement.JSONArray}
 */
proto.yorkie.v1.JSONElement.prototype.getJsonArray = function() {
  return /** @type{?proto.yorkie.v1.JSONElement.JSONArray} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement.JSONArray, 2));
};


/**
 * @param {?proto.yorkie.v1.JSONElement.JSONArray|undefined} value
 * @return {!proto.yorkie.v1.JSONElement} returns this
*/
proto.yorkie.v1.JSONElement.prototype.setJsonArray = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.yorkie.v1.JSONElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement} returns this
 */
proto.yorkie.v1.JSONElement.prototype.clearJsonArray = function() {
  return this.setJsonArray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.prototype.hasJsonArray = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Primitive primitive = 3;
 * @return {?proto.yorkie.v1.JSONElement.Primitive}
 */
proto.yorkie.v1.JSONElement.prototype.getPrimitive = function() {
  return /** @type{?proto.yorkie.v1.JSONElement.Primitive} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement.Primitive, 3));
};


/**
 * @param {?proto.yorkie.v1.JSONElement.Primitive|undefined} value
 * @return {!proto.yorkie.v1.JSONElement} returns this
*/
proto.yorkie.v1.JSONElement.prototype.setPrimitive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.yorkie.v1.JSONElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement} returns this
 */
proto.yorkie.v1.JSONElement.prototype.clearPrimitive = function() {
  return this.setPrimitive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.prototype.hasPrimitive = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Text text = 4;
 * @return {?proto.yorkie.v1.JSONElement.Text}
 */
proto.yorkie.v1.JSONElement.prototype.getText = function() {
  return /** @type{?proto.yorkie.v1.JSONElement.Text} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement.Text, 4));
};


/**
 * @param {?proto.yorkie.v1.JSONElement.Text|undefined} value
 * @return {!proto.yorkie.v1.JSONElement} returns this
*/
proto.yorkie.v1.JSONElement.prototype.setText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.yorkie.v1.JSONElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement} returns this
 */
proto.yorkie.v1.JSONElement.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.prototype.hasText = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RichText rich_text = 5;
 * @return {?proto.yorkie.v1.JSONElement.RichText}
 */
proto.yorkie.v1.JSONElement.prototype.getRichText = function() {
  return /** @type{?proto.yorkie.v1.JSONElement.RichText} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement.RichText, 5));
};


/**
 * @param {?proto.yorkie.v1.JSONElement.RichText|undefined} value
 * @return {!proto.yorkie.v1.JSONElement} returns this
*/
proto.yorkie.v1.JSONElement.prototype.setRichText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.yorkie.v1.JSONElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement} returns this
 */
proto.yorkie.v1.JSONElement.prototype.clearRichText = function() {
  return this.setRichText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.prototype.hasRichText = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Counter counter = 6;
 * @return {?proto.yorkie.v1.JSONElement.Counter}
 */
proto.yorkie.v1.JSONElement.prototype.getCounter = function() {
  return /** @type{?proto.yorkie.v1.JSONElement.Counter} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement.Counter, 6));
};


/**
 * @param {?proto.yorkie.v1.JSONElement.Counter|undefined} value
 * @return {!proto.yorkie.v1.JSONElement} returns this
*/
proto.yorkie.v1.JSONElement.prototype.setCounter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.yorkie.v1.JSONElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.JSONElement} returns this
 */
proto.yorkie.v1.JSONElement.prototype.clearCounter = function() {
  return this.setCounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.JSONElement.prototype.hasCounter = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.RHTNode.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.RHTNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.RHTNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RHTNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    element: (f = msg.getElement()) && proto.yorkie.v1.JSONElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.RHTNode}
 */
proto.yorkie.v1.RHTNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.RHTNode;
  return proto.yorkie.v1.RHTNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.RHTNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.RHTNode}
 */
proto.yorkie.v1.RHTNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.JSONElement;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.deserializeBinaryFromReader);
      msg.setElement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.RHTNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.RHTNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.RHTNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RHTNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getElement();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.JSONElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.yorkie.v1.RHTNode.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.RHTNode} returns this
 */
proto.yorkie.v1.RHTNode.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional JSONElement element = 2;
 * @return {?proto.yorkie.v1.JSONElement}
 */
proto.yorkie.v1.RHTNode.prototype.getElement = function() {
  return /** @type{?proto.yorkie.v1.JSONElement} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement, 2));
};


/**
 * @param {?proto.yorkie.v1.JSONElement|undefined} value
 * @return {!proto.yorkie.v1.RHTNode} returns this
*/
proto.yorkie.v1.RHTNode.prototype.setElement = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.RHTNode} returns this
 */
proto.yorkie.v1.RHTNode.prototype.clearElement = function() {
  return this.setElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.RHTNode.prototype.hasElement = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.RGANode.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.RGANode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.RGANode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RGANode.toObject = function(includeInstance, msg) {
  var f, obj = {
    next: (f = msg.getNext()) && proto.yorkie.v1.RGANode.toObject(includeInstance, f),
    element: (f = msg.getElement()) && proto.yorkie.v1.JSONElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.RGANode}
 */
proto.yorkie.v1.RGANode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.RGANode;
  return proto.yorkie.v1.RGANode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.RGANode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.RGANode}
 */
proto.yorkie.v1.RGANode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.RGANode;
      reader.readMessage(value,proto.yorkie.v1.RGANode.deserializeBinaryFromReader);
      msg.setNext(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.JSONElement;
      reader.readMessage(value,proto.yorkie.v1.JSONElement.deserializeBinaryFromReader);
      msg.setElement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.RGANode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.RGANode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.RGANode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RGANode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.RGANode.serializeBinaryToWriter
    );
  }
  f = message.getElement();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.JSONElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional RGANode next = 1;
 * @return {?proto.yorkie.v1.RGANode}
 */
proto.yorkie.v1.RGANode.prototype.getNext = function() {
  return /** @type{?proto.yorkie.v1.RGANode} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.RGANode, 1));
};


/**
 * @param {?proto.yorkie.v1.RGANode|undefined} value
 * @return {!proto.yorkie.v1.RGANode} returns this
*/
proto.yorkie.v1.RGANode.prototype.setNext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.RGANode} returns this
 */
proto.yorkie.v1.RGANode.prototype.clearNext = function() {
  return this.setNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.RGANode.prototype.hasNext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional JSONElement element = 2;
 * @return {?proto.yorkie.v1.JSONElement}
 */
proto.yorkie.v1.RGANode.prototype.getElement = function() {
  return /** @type{?proto.yorkie.v1.JSONElement} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.JSONElement, 2));
};


/**
 * @param {?proto.yorkie.v1.JSONElement|undefined} value
 * @return {!proto.yorkie.v1.RGANode} returns this
*/
proto.yorkie.v1.RGANode.prototype.setElement = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.RGANode} returns this
 */
proto.yorkie.v1.RGANode.prototype.clearElement = function() {
  return this.setElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.RGANode.prototype.hasElement = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.TextNode.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.TextNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.TextNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TextNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.yorkie.v1.TextNodeID.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    insPrevId: (f = msg.getInsPrevId()) && proto.yorkie.v1.TextNodeID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.TextNode}
 */
proto.yorkie.v1.TextNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.TextNode;
  return proto.yorkie.v1.TextNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.TextNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.TextNode}
 */
proto.yorkie.v1.TextNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TextNodeID;
      reader.readMessage(value,proto.yorkie.v1.TextNodeID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TextNodeID;
      reader.readMessage(value,proto.yorkie.v1.TextNodeID.deserializeBinaryFromReader);
      msg.setInsPrevId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.TextNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.TextNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.TextNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TextNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TextNodeID.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getInsPrevId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TextNodeID.serializeBinaryToWriter
    );
  }
};


/**
 * optional TextNodeID id = 1;
 * @return {?proto.yorkie.v1.TextNodeID}
 */
proto.yorkie.v1.TextNode.prototype.getId = function() {
  return /** @type{?proto.yorkie.v1.TextNodeID} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodeID, 1));
};


/**
 * @param {?proto.yorkie.v1.TextNodeID|undefined} value
 * @return {!proto.yorkie.v1.TextNode} returns this
*/
proto.yorkie.v1.TextNode.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.TextNode} returns this
 */
proto.yorkie.v1.TextNode.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.TextNode.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.yorkie.v1.TextNode.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.TextNode} returns this
 */
proto.yorkie.v1.TextNode.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TimeTicket removed_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.TextNode.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.TextNode} returns this
*/
proto.yorkie.v1.TextNode.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.TextNode} returns this
 */
proto.yorkie.v1.TextNode.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.TextNode.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TextNodeID ins_prev_id = 4;
 * @return {?proto.yorkie.v1.TextNodeID}
 */
proto.yorkie.v1.TextNode.prototype.getInsPrevId = function() {
  return /** @type{?proto.yorkie.v1.TextNodeID} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodeID, 4));
};


/**
 * @param {?proto.yorkie.v1.TextNodeID|undefined} value
 * @return {!proto.yorkie.v1.TextNode} returns this
*/
proto.yorkie.v1.TextNode.prototype.setInsPrevId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.TextNode} returns this
 */
proto.yorkie.v1.TextNode.prototype.clearInsPrevId = function() {
  return this.setInsPrevId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.TextNode.prototype.hasInsPrevId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.RichTextNodeAttr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.RichTextNodeAttr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RichTextNodeAttr.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedAt: (f = msg.getUpdatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.RichTextNodeAttr}
 */
proto.yorkie.v1.RichTextNodeAttr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.RichTextNodeAttr;
  return proto.yorkie.v1.RichTextNodeAttr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.RichTextNodeAttr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.RichTextNodeAttr}
 */
proto.yorkie.v1.RichTextNodeAttr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.RichTextNodeAttr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.RichTextNodeAttr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RichTextNodeAttr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.RichTextNodeAttr} returns this
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.RichTextNodeAttr} returns this
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TimeTicket updated_at = 3;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.getUpdatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 3));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.RichTextNodeAttr} returns this
*/
proto.yorkie.v1.RichTextNodeAttr.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.RichTextNodeAttr} returns this
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.RichTextNodeAttr.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.RichTextNode.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.RichTextNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.RichTextNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RichTextNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.yorkie.v1.TextNodeID.toObject(includeInstance, f),
    attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, proto.yorkie.v1.RichTextNodeAttr.toObject) : [],
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    removedAt: (f = msg.getRemovedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    insPrevId: (f = msg.getInsPrevId()) && proto.yorkie.v1.TextNodeID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.RichTextNode}
 */
proto.yorkie.v1.RichTextNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.RichTextNode;
  return proto.yorkie.v1.RichTextNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.RichTextNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.RichTextNode}
 */
proto.yorkie.v1.RichTextNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TextNodeID;
      reader.readMessage(value,proto.yorkie.v1.TextNodeID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.yorkie.v1.RichTextNodeAttr.deserializeBinaryFromReader, "", new proto.yorkie.v1.RichTextNodeAttr());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setRemovedAt(value);
      break;
    case 5:
      var value = new proto.yorkie.v1.TextNodeID;
      reader.readMessage(value,proto.yorkie.v1.TextNodeID.deserializeBinaryFromReader);
      msg.setInsPrevId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.RichTextNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.RichTextNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.RichTextNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.RichTextNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TextNodeID.serializeBinaryToWriter
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.yorkie.v1.RichTextNodeAttr.serializeBinaryToWriter);
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRemovedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getInsPrevId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.yorkie.v1.TextNodeID.serializeBinaryToWriter
    );
  }
};


/**
 * optional TextNodeID id = 1;
 * @return {?proto.yorkie.v1.TextNodeID}
 */
proto.yorkie.v1.RichTextNode.prototype.getId = function() {
  return /** @type{?proto.yorkie.v1.TextNodeID} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodeID, 1));
};


/**
 * @param {?proto.yorkie.v1.TextNodeID|undefined} value
 * @return {!proto.yorkie.v1.RichTextNode} returns this
*/
proto.yorkie.v1.RichTextNode.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.RichTextNode} returns this
 */
proto.yorkie.v1.RichTextNode.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.RichTextNode.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, RichTextNodeAttr> attributes = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.yorkie.v1.RichTextNodeAttr>}
 */
proto.yorkie.v1.RichTextNode.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.yorkie.v1.RichTextNodeAttr>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.yorkie.v1.RichTextNodeAttr));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yorkie.v1.RichTextNode} returns this
 */
proto.yorkie.v1.RichTextNode.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.yorkie.v1.RichTextNode.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.RichTextNode} returns this
 */
proto.yorkie.v1.RichTextNode.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TimeTicket removed_at = 4;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.RichTextNode.prototype.getRemovedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 4));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.RichTextNode} returns this
*/
proto.yorkie.v1.RichTextNode.prototype.setRemovedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.RichTextNode} returns this
 */
proto.yorkie.v1.RichTextNode.prototype.clearRemovedAt = function() {
  return this.setRemovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.RichTextNode.prototype.hasRemovedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TextNodeID ins_prev_id = 5;
 * @return {?proto.yorkie.v1.TextNodeID}
 */
proto.yorkie.v1.RichTextNode.prototype.getInsPrevId = function() {
  return /** @type{?proto.yorkie.v1.TextNodeID} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TextNodeID, 5));
};


/**
 * @param {?proto.yorkie.v1.TextNodeID|undefined} value
 * @return {!proto.yorkie.v1.RichTextNode} returns this
*/
proto.yorkie.v1.RichTextNode.prototype.setInsPrevId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.RichTextNode} returns this
 */
proto.yorkie.v1.RichTextNode.prototype.clearInsPrevId = function() {
  return this.setInsPrevId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.RichTextNode.prototype.hasInsPrevId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.TextNodeID.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.TextNodeID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.TextNodeID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TextNodeID.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.TextNodeID}
 */
proto.yorkie.v1.TextNodeID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.TextNodeID;
  return proto.yorkie.v1.TextNodeID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.TextNodeID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.TextNodeID}
 */
proto.yorkie.v1.TextNodeID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.TextNodeID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.TextNodeID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.TextNodeID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TextNodeID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional TimeTicket created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.TextNodeID.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.TextNodeID} returns this
*/
proto.yorkie.v1.TextNodeID.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.TextNodeID} returns this
 */
proto.yorkie.v1.TextNodeID.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.TextNodeID.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 offset = 2;
 * @return {number}
 */
proto.yorkie.v1.TextNodeID.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.yorkie.v1.TextNodeID} returns this
 */
proto.yorkie.v1.TextNodeID.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.User}
 */
proto.yorkie.v1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.User;
  return proto.yorkie.v1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.User}
 */
proto.yorkie.v1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yorkie.v1.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.User} returns this
 */
proto.yorkie.v1.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.yorkie.v1.User.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.User} returns this
 */
proto.yorkie.v1.User.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yorkie.v1.User.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yorkie.v1.User} returns this
*/
proto.yorkie.v1.User.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.User} returns this
 */
proto.yorkie.v1.User.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.User.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.Project.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Project.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Project.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Project} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Project.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secretKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    authWebhookUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    authWebhookMethodsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Project}
 */
proto.yorkie.v1.Project.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Project;
  return proto.yorkie.v1.Project.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Project} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Project}
 */
proto.yorkie.v1.Project.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretKey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthWebhookUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuthWebhookMethods(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Project.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Project.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Project} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Project.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecretKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAuthWebhookUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAuthWebhookMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yorkie.v1.Project.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.yorkie.v1.Project.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string public_key = 3;
 * @return {string}
 */
proto.yorkie.v1.Project.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string secret_key = 4;
 * @return {string}
 */
proto.yorkie.v1.Project.prototype.getSecretKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.setSecretKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string auth_webhook_url = 5;
 * @return {string}
 */
proto.yorkie.v1.Project.prototype.getAuthWebhookUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.setAuthWebhookUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string auth_webhook_methods = 6;
 * @return {!Array<string>}
 */
proto.yorkie.v1.Project.prototype.getAuthWebhookMethodsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.setAuthWebhookMethodsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.addAuthWebhookMethods = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.clearAuthWebhookMethodsList = function() {
  return this.setAuthWebhookMethodsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yorkie.v1.Project.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yorkie.v1.Project} returns this
*/
proto.yorkie.v1.Project.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Project.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yorkie.v1.Project.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yorkie.v1.Project} returns this
*/
proto.yorkie.v1.Project.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Project} returns this
 */
proto.yorkie.v1.Project.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Project.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.UpdatableProjectFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.UpdatableProjectFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatableProjectFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    authWebhookUrl: (f = msg.getAuthWebhookUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    authWebhookMethods: (f = msg.getAuthWebhookMethods()) && proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.UpdatableProjectFields}
 */
proto.yorkie.v1.UpdatableProjectFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.UpdatableProjectFields;
  return proto.yorkie.v1.UpdatableProjectFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.UpdatableProjectFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.UpdatableProjectFields}
 */
proto.yorkie.v1.UpdatableProjectFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthWebhookUrl(value);
      break;
    case 3:
      var value = new proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods;
      reader.readMessage(value,proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.deserializeBinaryFromReader);
      msg.setAuthWebhookMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.UpdatableProjectFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.UpdatableProjectFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatableProjectFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAuthWebhookUrl();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAuthWebhookMethods();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods}
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods;
  return proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods}
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string methods = 1;
 * @return {!Array<string>}
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.prototype.getMethodsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods} returns this
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.prototype.setMethodsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods} returns this
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.prototype.addMethods = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods} returns this
 */
proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods.prototype.clearMethodsList = function() {
  return this.setMethodsList([]);
};


/**
 * optional google.protobuf.StringValue name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.yorkie.v1.UpdatableProjectFields} returns this
*/
proto.yorkie.v1.UpdatableProjectFields.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.UpdatableProjectFields} returns this
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue auth_webhook_url = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.getAuthWebhookUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.yorkie.v1.UpdatableProjectFields} returns this
*/
proto.yorkie.v1.UpdatableProjectFields.prototype.setAuthWebhookUrl = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.UpdatableProjectFields} returns this
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.clearAuthWebhookUrl = function() {
  return this.setAuthWebhookUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.hasAuthWebhookUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AuthWebhookMethods auth_webhook_methods = 3;
 * @return {?proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.getAuthWebhookMethods = function() {
  return /** @type{?proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods, 3));
};


/**
 * @param {?proto.yorkie.v1.UpdatableProjectFields.AuthWebhookMethods|undefined} value
 * @return {!proto.yorkie.v1.UpdatableProjectFields} returns this
*/
proto.yorkie.v1.UpdatableProjectFields.prototype.setAuthWebhookMethods = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.UpdatableProjectFields} returns this
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.clearAuthWebhookMethods = function() {
  return this.setAuthWebhookMethods(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.UpdatableProjectFields.prototype.hasAuthWebhookMethods = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.DocumentSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.DocumentSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.DocumentSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DocumentSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    snapshot: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accessedAt: (f = msg.getAccessedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.DocumentSummary}
 */
proto.yorkie.v1.DocumentSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.DocumentSummary;
  return proto.yorkie.v1.DocumentSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.DocumentSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.DocumentSummary}
 */
proto.yorkie.v1.DocumentSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshot(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAccessedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DocumentSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.DocumentSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.DocumentSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DocumentSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSnapshot();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccessedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yorkie.v1.DocumentSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
 */
proto.yorkie.v1.DocumentSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.yorkie.v1.DocumentSummary.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
 */
proto.yorkie.v1.DocumentSummary.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string snapshot = 3;
 * @return {string}
 */
proto.yorkie.v1.DocumentSummary.prototype.getSnapshot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
 */
proto.yorkie.v1.DocumentSummary.prototype.setSnapshot = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yorkie.v1.DocumentSummary.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
*/
proto.yorkie.v1.DocumentSummary.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
 */
proto.yorkie.v1.DocumentSummary.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.DocumentSummary.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp accessed_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yorkie.v1.DocumentSummary.prototype.getAccessedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
*/
proto.yorkie.v1.DocumentSummary.prototype.setAccessedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
 */
proto.yorkie.v1.DocumentSummary.prototype.clearAccessedAt = function() {
  return this.setAccessedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.DocumentSummary.prototype.hasAccessedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yorkie.v1.DocumentSummary.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
*/
proto.yorkie.v1.DocumentSummary.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.DocumentSummary} returns this
 */
proto.yorkie.v1.DocumentSummary.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.DocumentSummary.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Presence.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Presence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Presence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Presence.toObject = function(includeInstance, msg) {
  var f, obj = {
    clock: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Presence}
 */
proto.yorkie.v1.Presence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Presence;
  return proto.yorkie.v1.Presence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Presence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Presence}
 */
proto.yorkie.v1.Presence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClock(value);
      break;
    case 2:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Presence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Presence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Presence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Presence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClock();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional int32 clock = 1;
 * @return {number}
 */
proto.yorkie.v1.Presence.prototype.getClock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.yorkie.v1.Presence} returns this
 */
proto.yorkie.v1.Presence.prototype.setClock = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<string, string> data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yorkie.v1.Presence.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yorkie.v1.Presence} returns this
 */
proto.yorkie.v1.Presence.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Client.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Client.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Client} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Client.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    presence: (f = msg.getPresence()) && proto.yorkie.v1.Presence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Client}
 */
proto.yorkie.v1.Client.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Client;
  return proto.yorkie.v1.Client.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Client} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Client}
 */
proto.yorkie.v1.Client.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.Presence;
      reader.readMessage(value,proto.yorkie.v1.Presence.deserializeBinaryFromReader);
      msg.setPresence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Client.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Client.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Client} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Client.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPresence();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.Presence.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {string}
 */
proto.yorkie.v1.Client.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.yorkie.v1.Client.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Client.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.Client} returns this
 */
proto.yorkie.v1.Client.prototype.setId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional Presence presence = 2;
 * @return {?proto.yorkie.v1.Presence}
 */
proto.yorkie.v1.Client.prototype.getPresence = function() {
  return /** @type{?proto.yorkie.v1.Presence} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Presence, 2));
};


/**
 * @param {?proto.yorkie.v1.Presence|undefined} value
 * @return {!proto.yorkie.v1.Client} returns this
*/
proto.yorkie.v1.Client.prototype.setPresence = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.Client} returns this
 */
proto.yorkie.v1.Client.prototype.clearPresence = function() {
  return this.setPresence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.Client.prototype.hasPresence = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.Clients.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Clients.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Clients.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Clients} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Clients.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    proto.yorkie.v1.Client.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Clients}
 */
proto.yorkie.v1.Clients.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Clients;
  return proto.yorkie.v1.Clients.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Clients} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Clients}
 */
proto.yorkie.v1.Clients.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.Client;
      reader.readMessage(value,proto.yorkie.v1.Client.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Clients.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Clients.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Clients} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Clients.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.yorkie.v1.Client.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Client clients = 1;
 * @return {!Array<!proto.yorkie.v1.Client>}
 */
proto.yorkie.v1.Clients.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.yorkie.v1.Client>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yorkie.v1.Client, 1));
};


/**
 * @param {!Array<!proto.yorkie.v1.Client>} value
 * @return {!proto.yorkie.v1.Clients} returns this
*/
proto.yorkie.v1.Clients.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.yorkie.v1.Client=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.Client}
 */
proto.yorkie.v1.Clients.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.yorkie.v1.Client, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.Clients} returns this
 */
proto.yorkie.v1.Clients.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.Checkpoint.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.Checkpoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.Checkpoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Checkpoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverSeq: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    clientSeq: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.Checkpoint}
 */
proto.yorkie.v1.Checkpoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.Checkpoint;
  return proto.yorkie.v1.Checkpoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.Checkpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.Checkpoint}
 */
proto.yorkie.v1.Checkpoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setServerSeq(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClientSeq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.Checkpoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.Checkpoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.Checkpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.Checkpoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerSeq();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getClientSeq();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional int64 server_seq = 1;
 * @return {string}
 */
proto.yorkie.v1.Checkpoint.prototype.getServerSeq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.Checkpoint} returns this
 */
proto.yorkie.v1.Checkpoint.prototype.setServerSeq = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint32 client_seq = 2;
 * @return {number}
 */
proto.yorkie.v1.Checkpoint.prototype.getClientSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.yorkie.v1.Checkpoint} returns this
 */
proto.yorkie.v1.Checkpoint.prototype.setClientSeq = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.TextNodePos.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.TextNodePos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.TextNodePos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TextNodePos.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && proto.yorkie.v1.TimeTicket.toObject(includeInstance, f),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    relativeOffset: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.TextNodePos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.TextNodePos;
  return proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.TextNodePos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.TextNodePos}
 */
proto.yorkie.v1.TextNodePos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.TimeTicket;
      reader.readMessage(value,proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRelativeOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.TextNodePos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.TextNodePos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.TextNodePos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TextNodePos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.TimeTicket.serializeBinaryToWriter
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRelativeOffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional TimeTicket created_at = 1;
 * @return {?proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.TextNodePos.prototype.getCreatedAt = function() {
  return /** @type{?proto.yorkie.v1.TimeTicket} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.TimeTicket, 1));
};


/**
 * @param {?proto.yorkie.v1.TimeTicket|undefined} value
 * @return {!proto.yorkie.v1.TextNodePos} returns this
*/
proto.yorkie.v1.TextNodePos.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.TextNodePos} returns this
 */
proto.yorkie.v1.TextNodePos.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.TextNodePos.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 offset = 2;
 * @return {number}
 */
proto.yorkie.v1.TextNodePos.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.yorkie.v1.TextNodePos} returns this
 */
proto.yorkie.v1.TextNodePos.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 relative_offset = 3;
 * @return {number}
 */
proto.yorkie.v1.TextNodePos.prototype.getRelativeOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.yorkie.v1.TextNodePos} returns this
 */
proto.yorkie.v1.TextNodePos.prototype.setRelativeOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.TimeTicket.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.TimeTicket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.TimeTicket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TimeTicket.toObject = function(includeInstance, msg) {
  var f, obj = {
    lamport: jspb.Message.getFieldWithDefault(msg, 1, "0"),
    delimiter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actorId: msg.getActorId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.TimeTicket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.TimeTicket;
  return proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.TimeTicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.TimeTicket}
 */
proto.yorkie.v1.TimeTicket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setLamport(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelimiter(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setActorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.TimeTicket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.TimeTicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.TimeTicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.TimeTicket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLamport();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      1,
      f
    );
  }
  f = message.getDelimiter();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getActorId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional int64 lamport = 1;
 * @return {string}
 */
proto.yorkie.v1.TimeTicket.prototype.getLamport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, "0"));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.TimeTicket} returns this
 */
proto.yorkie.v1.TimeTicket.prototype.setLamport = function(value) {
  return jspb.Message.setProto3StringIntField(this, 1, value);
};


/**
 * optional uint32 delimiter = 2;
 * @return {number}
 */
proto.yorkie.v1.TimeTicket.prototype.getDelimiter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.yorkie.v1.TimeTicket} returns this
 */
proto.yorkie.v1.TimeTicket.prototype.setDelimiter = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes actor_id = 3;
 * @return {string}
 */
proto.yorkie.v1.TimeTicket.prototype.getActorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes actor_id = 3;
 * This is a type-conversion wrapper around `getActorId()`
 * @return {string}
 */
proto.yorkie.v1.TimeTicket.prototype.getActorId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getActorId()));
};


/**
 * optional bytes actor_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getActorId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.TimeTicket.prototype.getActorId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getActorId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.TimeTicket} returns this
 */
proto.yorkie.v1.TimeTicket.prototype.setActorId = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.DocEvent.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.DocEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.DocEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.DocEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DocEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    publisher: (f = msg.getPublisher()) && proto.yorkie.v1.Client.toObject(includeInstance, f),
    documentKeysList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.DocEvent}
 */
proto.yorkie.v1.DocEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.DocEvent;
  return proto.yorkie.v1.DocEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.DocEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.DocEvent}
 */
proto.yorkie.v1.DocEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.yorkie.v1.DocEventType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.yorkie.v1.Client;
      reader.readMessage(value,proto.yorkie.v1.Client.deserializeBinaryFromReader);
      msg.setPublisher(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DocEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.DocEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.DocEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DocEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPublisher();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.yorkie.v1.Client.serializeBinaryToWriter
    );
  }
  f = message.getDocumentKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional DocEventType type = 1;
 * @return {!proto.yorkie.v1.DocEventType}
 */
proto.yorkie.v1.DocEvent.prototype.getType = function() {
  return /** @type {!proto.yorkie.v1.DocEventType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.yorkie.v1.DocEventType} value
 * @return {!proto.yorkie.v1.DocEvent} returns this
 */
proto.yorkie.v1.DocEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Client publisher = 2;
 * @return {?proto.yorkie.v1.Client}
 */
proto.yorkie.v1.DocEvent.prototype.getPublisher = function() {
  return /** @type{?proto.yorkie.v1.Client} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.Client, 2));
};


/**
 * @param {?proto.yorkie.v1.Client|undefined} value
 * @return {!proto.yorkie.v1.DocEvent} returns this
*/
proto.yorkie.v1.DocEvent.prototype.setPublisher = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.DocEvent} returns this
 */
proto.yorkie.v1.DocEvent.prototype.clearPublisher = function() {
  return this.setPublisher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.DocEvent.prototype.hasPublisher = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string document_keys = 3;
 * @return {!Array<string>}
 */
proto.yorkie.v1.DocEvent.prototype.getDocumentKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yorkie.v1.DocEvent} returns this
 */
proto.yorkie.v1.DocEvent.prototype.setDocumentKeysList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.DocEvent} returns this
 */
proto.yorkie.v1.DocEvent.prototype.addDocumentKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.DocEvent} returns this
 */
proto.yorkie.v1.DocEvent.prototype.clearDocumentKeysList = function() {
  return this.setDocumentKeysList([]);
};


/**
 * @enum {number}
 */
proto.yorkie.v1.ValueType = {
  VALUE_TYPE_NULL: 0,
  VALUE_TYPE_BOOLEAN: 1,
  VALUE_TYPE_INTEGER: 2,
  VALUE_TYPE_LONG: 3,
  VALUE_TYPE_DOUBLE: 4,
  VALUE_TYPE_STRING: 5,
  VALUE_TYPE_BYTES: 6,
  VALUE_TYPE_DATE: 7,
  VALUE_TYPE_JSON_OBJECT: 8,
  VALUE_TYPE_JSON_ARRAY: 9,
  VALUE_TYPE_TEXT: 10,
  VALUE_TYPE_RICH_TEXT: 11,
  VALUE_TYPE_INTEGER_CNT: 12,
  VALUE_TYPE_LONG_CNT: 13,
  VALUE_TYPE_DOUBLE_CNT: 14
};

/**
 * @enum {number}
 */
proto.yorkie.v1.DocEventType = {
  DOC_EVENT_TYPE_DOCUMENTS_CHANGED: 0,
  DOC_EVENT_TYPE_DOCUMENTS_WATCHED: 1,
  DOC_EVENT_TYPE_DOCUMENTS_UNWATCHED: 2,
  DOC_EVENT_TYPE_PRESENCE_CHANGED: 3
};

goog.object.extend(exports, proto.yorkie.v1);

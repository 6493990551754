// source: yorkie/v1/yorkie.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var yorkie_v1_resources_pb = require('../../yorkie/v1/resources_pb.js');
goog.object.extend(proto, yorkie_v1_resources_pb);
goog.exportSymbol('proto.yorkie.v1.ActivateClientRequest', null, global);
goog.exportSymbol('proto.yorkie.v1.ActivateClientResponse', null, global);
goog.exportSymbol('proto.yorkie.v1.AttachDocumentRequest', null, global);
goog.exportSymbol('proto.yorkie.v1.AttachDocumentResponse', null, global);
goog.exportSymbol('proto.yorkie.v1.DeactivateClientRequest', null, global);
goog.exportSymbol('proto.yorkie.v1.DeactivateClientResponse', null, global);
goog.exportSymbol('proto.yorkie.v1.DetachDocumentRequest', null, global);
goog.exportSymbol('proto.yorkie.v1.DetachDocumentResponse', null, global);
goog.exportSymbol('proto.yorkie.v1.PushPullRequest', null, global);
goog.exportSymbol('proto.yorkie.v1.PushPullResponse', null, global);
goog.exportSymbol('proto.yorkie.v1.UpdatePresenceRequest', null, global);
goog.exportSymbol('proto.yorkie.v1.UpdatePresenceResponse', null, global);
goog.exportSymbol('proto.yorkie.v1.WatchDocumentsRequest', null, global);
goog.exportSymbol('proto.yorkie.v1.WatchDocumentsResponse', null, global);
goog.exportSymbol('proto.yorkie.v1.WatchDocumentsResponse.BodyCase', null, global);
goog.exportSymbol('proto.yorkie.v1.WatchDocumentsResponse.Initialization', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.ActivateClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.ActivateClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.ActivateClientRequest.displayName = 'proto.yorkie.v1.ActivateClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.ActivateClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.ActivateClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.ActivateClientResponse.displayName = 'proto.yorkie.v1.ActivateClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.DeactivateClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.DeactivateClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.DeactivateClientRequest.displayName = 'proto.yorkie.v1.DeactivateClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.DeactivateClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.DeactivateClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.DeactivateClientResponse.displayName = 'proto.yorkie.v1.DeactivateClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.AttachDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.AttachDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.AttachDocumentRequest.displayName = 'proto.yorkie.v1.AttachDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.AttachDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.AttachDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.AttachDocumentResponse.displayName = 'proto.yorkie.v1.AttachDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.DetachDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.DetachDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.DetachDocumentRequest.displayName = 'proto.yorkie.v1.DetachDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.DetachDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.DetachDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.DetachDocumentResponse.displayName = 'proto.yorkie.v1.DetachDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.WatchDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.WatchDocumentsRequest.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.WatchDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.WatchDocumentsRequest.displayName = 'proto.yorkie.v1.WatchDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.WatchDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.yorkie.v1.WatchDocumentsResponse.oneofGroups_);
};
goog.inherits(proto.yorkie.v1.WatchDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.WatchDocumentsResponse.displayName = 'proto.yorkie.v1.WatchDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.WatchDocumentsResponse.Initialization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.WatchDocumentsResponse.Initialization.displayName = 'proto.yorkie.v1.WatchDocumentsResponse.Initialization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.PushPullRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.PushPullRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.PushPullRequest.displayName = 'proto.yorkie.v1.PushPullRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.PushPullResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.PushPullResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.PushPullResponse.displayName = 'proto.yorkie.v1.PushPullResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.UpdatePresenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yorkie.v1.UpdatePresenceRequest.repeatedFields_, null);
};
goog.inherits(proto.yorkie.v1.UpdatePresenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.UpdatePresenceRequest.displayName = 'proto.yorkie.v1.UpdatePresenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yorkie.v1.UpdatePresenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yorkie.v1.UpdatePresenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yorkie.v1.UpdatePresenceResponse.displayName = 'proto.yorkie.v1.UpdatePresenceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.ActivateClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.ActivateClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.ActivateClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ActivateClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.ActivateClientRequest}
 */
proto.yorkie.v1.ActivateClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.ActivateClientRequest;
  return proto.yorkie.v1.ActivateClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.ActivateClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.ActivateClientRequest}
 */
proto.yorkie.v1.ActivateClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.ActivateClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.ActivateClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.ActivateClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ActivateClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string client_key = 1;
 * @return {string}
 */
proto.yorkie.v1.ActivateClientRequest.prototype.getClientKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.ActivateClientRequest} returns this
 */
proto.yorkie.v1.ActivateClientRequest.prototype.setClientKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.ActivateClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.ActivateClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.ActivateClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ActivateClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: msg.getClientId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.ActivateClientResponse}
 */
proto.yorkie.v1.ActivateClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.ActivateClientResponse;
  return proto.yorkie.v1.ActivateClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.ActivateClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.ActivateClientResponse}
 */
proto.yorkie.v1.ActivateClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientKey(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.ActivateClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.ActivateClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.ActivateClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.ActivateClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string client_key = 1;
 * @return {string}
 */
proto.yorkie.v1.ActivateClientResponse.prototype.getClientKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.ActivateClientResponse} returns this
 */
proto.yorkie.v1.ActivateClientResponse.prototype.setClientKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes client_id = 2;
 * @return {string}
 */
proto.yorkie.v1.ActivateClientResponse.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes client_id = 2;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.ActivateClientResponse.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.ActivateClientResponse.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.ActivateClientResponse} returns this
 */
proto.yorkie.v1.ActivateClientResponse.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.DeactivateClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.DeactivateClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.DeactivateClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DeactivateClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: msg.getClientId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.DeactivateClientRequest}
 */
proto.yorkie.v1.DeactivateClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.DeactivateClientRequest;
  return proto.yorkie.v1.DeactivateClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.DeactivateClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.DeactivateClientRequest}
 */
proto.yorkie.v1.DeactivateClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DeactivateClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.DeactivateClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.DeactivateClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DeactivateClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes client_id = 1;
 * @return {string}
 */
proto.yorkie.v1.DeactivateClientRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_id = 1;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.DeactivateClientRequest.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DeactivateClientRequest.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.DeactivateClientRequest} returns this
 */
proto.yorkie.v1.DeactivateClientRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.DeactivateClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.DeactivateClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.DeactivateClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DeactivateClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: msg.getClientId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.DeactivateClientResponse}
 */
proto.yorkie.v1.DeactivateClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.DeactivateClientResponse;
  return proto.yorkie.v1.DeactivateClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.DeactivateClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.DeactivateClientResponse}
 */
proto.yorkie.v1.DeactivateClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DeactivateClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.DeactivateClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.DeactivateClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DeactivateClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes client_id = 1;
 * @return {string}
 */
proto.yorkie.v1.DeactivateClientResponse.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_id = 1;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.DeactivateClientResponse.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DeactivateClientResponse.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.DeactivateClientResponse} returns this
 */
proto.yorkie.v1.DeactivateClientResponse.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.AttachDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.AttachDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.AttachDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: msg.getClientId_asB64(),
    changePack: (f = msg.getChangePack()) && yorkie_v1_resources_pb.ChangePack.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.AttachDocumentRequest}
 */
proto.yorkie.v1.AttachDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.AttachDocumentRequest;
  return proto.yorkie.v1.AttachDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.AttachDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.AttachDocumentRequest}
 */
proto.yorkie.v1.AttachDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    case 2:
      var value = new yorkie_v1_resources_pb.ChangePack;
      reader.readMessage(value,yorkie_v1_resources_pb.ChangePack.deserializeBinaryFromReader);
      msg.setChangePack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.AttachDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.AttachDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.AttachDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getChangePack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yorkie_v1_resources_pb.ChangePack.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes client_id = 1;
 * @return {string}
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_id = 1;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.AttachDocumentRequest} returns this
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional ChangePack change_pack = 2;
 * @return {?proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.getChangePack = function() {
  return /** @type{?proto.yorkie.v1.ChangePack} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.ChangePack, 2));
};


/**
 * @param {?proto.yorkie.v1.ChangePack|undefined} value
 * @return {!proto.yorkie.v1.AttachDocumentRequest} returns this
*/
proto.yorkie.v1.AttachDocumentRequest.prototype.setChangePack = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.AttachDocumentRequest} returns this
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.clearChangePack = function() {
  return this.setChangePack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.AttachDocumentRequest.prototype.hasChangePack = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.AttachDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.AttachDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.AttachDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: msg.getClientId_asB64(),
    changePack: (f = msg.getChangePack()) && yorkie_v1_resources_pb.ChangePack.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.AttachDocumentResponse}
 */
proto.yorkie.v1.AttachDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.AttachDocumentResponse;
  return proto.yorkie.v1.AttachDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.AttachDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.AttachDocumentResponse}
 */
proto.yorkie.v1.AttachDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    case 2:
      var value = new yorkie_v1_resources_pb.ChangePack;
      reader.readMessage(value,yorkie_v1_resources_pb.ChangePack.deserializeBinaryFromReader);
      msg.setChangePack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.AttachDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.AttachDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.AttachDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getChangePack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yorkie_v1_resources_pb.ChangePack.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes client_id = 1;
 * @return {string}
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_id = 1;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.AttachDocumentResponse} returns this
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional ChangePack change_pack = 2;
 * @return {?proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.getChangePack = function() {
  return /** @type{?proto.yorkie.v1.ChangePack} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.ChangePack, 2));
};


/**
 * @param {?proto.yorkie.v1.ChangePack|undefined} value
 * @return {!proto.yorkie.v1.AttachDocumentResponse} returns this
*/
proto.yorkie.v1.AttachDocumentResponse.prototype.setChangePack = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.AttachDocumentResponse} returns this
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.clearChangePack = function() {
  return this.setChangePack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.AttachDocumentResponse.prototype.hasChangePack = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.DetachDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.DetachDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DetachDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: msg.getClientId_asB64(),
    changePack: (f = msg.getChangePack()) && yorkie_v1_resources_pb.ChangePack.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.DetachDocumentRequest}
 */
proto.yorkie.v1.DetachDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.DetachDocumentRequest;
  return proto.yorkie.v1.DetachDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.DetachDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.DetachDocumentRequest}
 */
proto.yorkie.v1.DetachDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    case 2:
      var value = new yorkie_v1_resources_pb.ChangePack;
      reader.readMessage(value,yorkie_v1_resources_pb.ChangePack.deserializeBinaryFromReader);
      msg.setChangePack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.DetachDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.DetachDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DetachDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getChangePack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yorkie_v1_resources_pb.ChangePack.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes client_id = 1;
 * @return {string}
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_id = 1;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.DetachDocumentRequest} returns this
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional ChangePack change_pack = 2;
 * @return {?proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.getChangePack = function() {
  return /** @type{?proto.yorkie.v1.ChangePack} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.ChangePack, 2));
};


/**
 * @param {?proto.yorkie.v1.ChangePack|undefined} value
 * @return {!proto.yorkie.v1.DetachDocumentRequest} returns this
*/
proto.yorkie.v1.DetachDocumentRequest.prototype.setChangePack = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.DetachDocumentRequest} returns this
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.clearChangePack = function() {
  return this.setChangePack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.DetachDocumentRequest.prototype.hasChangePack = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.DetachDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.DetachDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.DetachDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DetachDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changePack: (f = msg.getChangePack()) && yorkie_v1_resources_pb.ChangePack.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.DetachDocumentResponse}
 */
proto.yorkie.v1.DetachDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.DetachDocumentResponse;
  return proto.yorkie.v1.DetachDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.DetachDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.DetachDocumentResponse}
 */
proto.yorkie.v1.DetachDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientKey(value);
      break;
    case 2:
      var value = new yorkie_v1_resources_pb.ChangePack;
      reader.readMessage(value,yorkie_v1_resources_pb.ChangePack.deserializeBinaryFromReader);
      msg.setChangePack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.DetachDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.DetachDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.DetachDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.DetachDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangePack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yorkie_v1_resources_pb.ChangePack.serializeBinaryToWriter
    );
  }
};


/**
 * optional string client_key = 1;
 * @return {string}
 */
proto.yorkie.v1.DetachDocumentResponse.prototype.getClientKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yorkie.v1.DetachDocumentResponse} returns this
 */
proto.yorkie.v1.DetachDocumentResponse.prototype.setClientKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ChangePack change_pack = 2;
 * @return {?proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.DetachDocumentResponse.prototype.getChangePack = function() {
  return /** @type{?proto.yorkie.v1.ChangePack} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.ChangePack, 2));
};


/**
 * @param {?proto.yorkie.v1.ChangePack|undefined} value
 * @return {!proto.yorkie.v1.DetachDocumentResponse} returns this
*/
proto.yorkie.v1.DetachDocumentResponse.prototype.setChangePack = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.DetachDocumentResponse} returns this
 */
proto.yorkie.v1.DetachDocumentResponse.prototype.clearChangePack = function() {
  return this.setChangePack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.DetachDocumentResponse.prototype.hasChangePack = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.WatchDocumentsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.WatchDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.WatchDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.WatchDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && yorkie_v1_resources_pb.Client.toObject(includeInstance, f),
    documentKeysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.WatchDocumentsRequest}
 */
proto.yorkie.v1.WatchDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.WatchDocumentsRequest;
  return proto.yorkie.v1.WatchDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.WatchDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.WatchDocumentsRequest}
 */
proto.yorkie.v1.WatchDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new yorkie_v1_resources_pb.Client;
      reader.readMessage(value,yorkie_v1_resources_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.WatchDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.WatchDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.WatchDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      yorkie_v1_resources_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getDocumentKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.yorkie.v1.Client}
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.getClient = function() {
  return /** @type{?proto.yorkie.v1.Client} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.Client, 1));
};


/**
 * @param {?proto.yorkie.v1.Client|undefined} value
 * @return {!proto.yorkie.v1.WatchDocumentsRequest} returns this
*/
proto.yorkie.v1.WatchDocumentsRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.WatchDocumentsRequest} returns this
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string document_keys = 2;
 * @return {!Array<string>}
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.getDocumentKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yorkie.v1.WatchDocumentsRequest} returns this
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.setDocumentKeysList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.WatchDocumentsRequest} returns this
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.addDocumentKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.WatchDocumentsRequest} returns this
 */
proto.yorkie.v1.WatchDocumentsRequest.prototype.clearDocumentKeysList = function() {
  return this.setDocumentKeysList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.yorkie.v1.WatchDocumentsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.yorkie.v1.WatchDocumentsResponse.BodyCase = {
  BODY_NOT_SET: 0,
  INITIALIZATION: 1,
  EVENT: 2
};

/**
 * @return {proto.yorkie.v1.WatchDocumentsResponse.BodyCase}
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.getBodyCase = function() {
  return /** @type {proto.yorkie.v1.WatchDocumentsResponse.BodyCase} */(jspb.Message.computeOneofCase(this, proto.yorkie.v1.WatchDocumentsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.WatchDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.WatchDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.WatchDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialization: (f = msg.getInitialization()) && proto.yorkie.v1.WatchDocumentsResponse.Initialization.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && yorkie_v1_resources_pb.DocEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.WatchDocumentsResponse}
 */
proto.yorkie.v1.WatchDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.WatchDocumentsResponse;
  return proto.yorkie.v1.WatchDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.WatchDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.WatchDocumentsResponse}
 */
proto.yorkie.v1.WatchDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yorkie.v1.WatchDocumentsResponse.Initialization;
      reader.readMessage(value,proto.yorkie.v1.WatchDocumentsResponse.Initialization.deserializeBinaryFromReader);
      msg.setInitialization(value);
      break;
    case 2:
      var value = new yorkie_v1_resources_pb.DocEvent;
      reader.readMessage(value,yorkie_v1_resources_pb.DocEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.WatchDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.WatchDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.WatchDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yorkie.v1.WatchDocumentsResponse.Initialization.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yorkie_v1_resources_pb.DocEvent.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.WatchDocumentsResponse.Initialization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.WatchDocumentsResponse.Initialization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.toObject = function(includeInstance, msg) {
  var f, obj = {
    peersMapByDocMap: (f = msg.getPeersMapByDocMap()) ? f.toObject(includeInstance, proto.yorkie.v1.Clients.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.WatchDocumentsResponse.Initialization}
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.WatchDocumentsResponse.Initialization;
  return proto.yorkie.v1.WatchDocumentsResponse.Initialization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.WatchDocumentsResponse.Initialization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.WatchDocumentsResponse.Initialization}
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getPeersMapByDocMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.yorkie.v1.Clients.deserializeBinaryFromReader, "", new proto.yorkie.v1.Clients());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.WatchDocumentsResponse.Initialization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.WatchDocumentsResponse.Initialization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeersMapByDocMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.yorkie.v1.Clients.serializeBinaryToWriter);
  }
};


/**
 * map<string, Clients> peers_map_by_doc = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.yorkie.v1.Clients>}
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.prototype.getPeersMapByDocMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.yorkie.v1.Clients>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.yorkie.v1.Clients));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yorkie.v1.WatchDocumentsResponse.Initialization} returns this
 */
proto.yorkie.v1.WatchDocumentsResponse.Initialization.prototype.clearPeersMapByDocMap = function() {
  this.getPeersMapByDocMap().clear();
  return this;};


/**
 * optional Initialization initialization = 1;
 * @return {?proto.yorkie.v1.WatchDocumentsResponse.Initialization}
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.getInitialization = function() {
  return /** @type{?proto.yorkie.v1.WatchDocumentsResponse.Initialization} */ (
    jspb.Message.getWrapperField(this, proto.yorkie.v1.WatchDocumentsResponse.Initialization, 1));
};


/**
 * @param {?proto.yorkie.v1.WatchDocumentsResponse.Initialization|undefined} value
 * @return {!proto.yorkie.v1.WatchDocumentsResponse} returns this
*/
proto.yorkie.v1.WatchDocumentsResponse.prototype.setInitialization = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.yorkie.v1.WatchDocumentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.WatchDocumentsResponse} returns this
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.clearInitialization = function() {
  return this.setInitialization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.hasInitialization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DocEvent event = 2;
 * @return {?proto.yorkie.v1.DocEvent}
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.getEvent = function() {
  return /** @type{?proto.yorkie.v1.DocEvent} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.DocEvent, 2));
};


/**
 * @param {?proto.yorkie.v1.DocEvent|undefined} value
 * @return {!proto.yorkie.v1.WatchDocumentsResponse} returns this
*/
proto.yorkie.v1.WatchDocumentsResponse.prototype.setEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.yorkie.v1.WatchDocumentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.WatchDocumentsResponse} returns this
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.WatchDocumentsResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.PushPullRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.PushPullRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.PushPullRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.PushPullRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: msg.getClientId_asB64(),
    changePack: (f = msg.getChangePack()) && yorkie_v1_resources_pb.ChangePack.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.PushPullRequest}
 */
proto.yorkie.v1.PushPullRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.PushPullRequest;
  return proto.yorkie.v1.PushPullRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.PushPullRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.PushPullRequest}
 */
proto.yorkie.v1.PushPullRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    case 2:
      var value = new yorkie_v1_resources_pb.ChangePack;
      reader.readMessage(value,yorkie_v1_resources_pb.ChangePack.deserializeBinaryFromReader);
      msg.setChangePack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.PushPullRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.PushPullRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.PushPullRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.PushPullRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getChangePack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yorkie_v1_resources_pb.ChangePack.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes client_id = 1;
 * @return {string}
 */
proto.yorkie.v1.PushPullRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_id = 1;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.PushPullRequest.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.PushPullRequest.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.PushPullRequest} returns this
 */
proto.yorkie.v1.PushPullRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional ChangePack change_pack = 2;
 * @return {?proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.PushPullRequest.prototype.getChangePack = function() {
  return /** @type{?proto.yorkie.v1.ChangePack} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.ChangePack, 2));
};


/**
 * @param {?proto.yorkie.v1.ChangePack|undefined} value
 * @return {!proto.yorkie.v1.PushPullRequest} returns this
*/
proto.yorkie.v1.PushPullRequest.prototype.setChangePack = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.PushPullRequest} returns this
 */
proto.yorkie.v1.PushPullRequest.prototype.clearChangePack = function() {
  return this.setChangePack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.PushPullRequest.prototype.hasChangePack = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.PushPullResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.PushPullResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.PushPullResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.PushPullResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: msg.getClientId_asB64(),
    changePack: (f = msg.getChangePack()) && yorkie_v1_resources_pb.ChangePack.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.PushPullResponse}
 */
proto.yorkie.v1.PushPullResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.PushPullResponse;
  return proto.yorkie.v1.PushPullResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.PushPullResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.PushPullResponse}
 */
proto.yorkie.v1.PushPullResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setClientId(value);
      break;
    case 2:
      var value = new yorkie_v1_resources_pb.ChangePack;
      reader.readMessage(value,yorkie_v1_resources_pb.ChangePack.deserializeBinaryFromReader);
      msg.setChangePack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.PushPullResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.PushPullResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.PushPullResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.PushPullResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getChangePack();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yorkie_v1_resources_pb.ChangePack.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes client_id = 1;
 * @return {string}
 */
proto.yorkie.v1.PushPullResponse.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes client_id = 1;
 * This is a type-conversion wrapper around `getClientId()`
 * @return {string}
 */
proto.yorkie.v1.PushPullResponse.prototype.getClientId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getClientId()));
};


/**
 * optional bytes client_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getClientId()`
 * @return {!Uint8Array}
 */
proto.yorkie.v1.PushPullResponse.prototype.getClientId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getClientId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yorkie.v1.PushPullResponse} returns this
 */
proto.yorkie.v1.PushPullResponse.prototype.setClientId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional ChangePack change_pack = 2;
 * @return {?proto.yorkie.v1.ChangePack}
 */
proto.yorkie.v1.PushPullResponse.prototype.getChangePack = function() {
  return /** @type{?proto.yorkie.v1.ChangePack} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.ChangePack, 2));
};


/**
 * @param {?proto.yorkie.v1.ChangePack|undefined} value
 * @return {!proto.yorkie.v1.PushPullResponse} returns this
*/
proto.yorkie.v1.PushPullResponse.prototype.setChangePack = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.PushPullResponse} returns this
 */
proto.yorkie.v1.PushPullResponse.prototype.clearChangePack = function() {
  return this.setChangePack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.PushPullResponse.prototype.hasChangePack = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yorkie.v1.UpdatePresenceRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.UpdatePresenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.UpdatePresenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatePresenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && yorkie_v1_resources_pb.Client.toObject(includeInstance, f),
    documentKeysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.UpdatePresenceRequest}
 */
proto.yorkie.v1.UpdatePresenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.UpdatePresenceRequest;
  return proto.yorkie.v1.UpdatePresenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.UpdatePresenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.UpdatePresenceRequest}
 */
proto.yorkie.v1.UpdatePresenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new yorkie_v1_resources_pb.Client;
      reader.readMessage(value,yorkie_v1_resources_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.UpdatePresenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.UpdatePresenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatePresenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      yorkie_v1_resources_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getDocumentKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.yorkie.v1.Client}
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.getClient = function() {
  return /** @type{?proto.yorkie.v1.Client} */ (
    jspb.Message.getWrapperField(this, yorkie_v1_resources_pb.Client, 1));
};


/**
 * @param {?proto.yorkie.v1.Client|undefined} value
 * @return {!proto.yorkie.v1.UpdatePresenceRequest} returns this
*/
proto.yorkie.v1.UpdatePresenceRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yorkie.v1.UpdatePresenceRequest} returns this
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string document_keys = 2;
 * @return {!Array<string>}
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.getDocumentKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yorkie.v1.UpdatePresenceRequest} returns this
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.setDocumentKeysList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yorkie.v1.UpdatePresenceRequest} returns this
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.addDocumentKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yorkie.v1.UpdatePresenceRequest} returns this
 */
proto.yorkie.v1.UpdatePresenceRequest.prototype.clearDocumentKeysList = function() {
  return this.setDocumentKeysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yorkie.v1.UpdatePresenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yorkie.v1.UpdatePresenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yorkie.v1.UpdatePresenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatePresenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yorkie.v1.UpdatePresenceResponse}
 */
proto.yorkie.v1.UpdatePresenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yorkie.v1.UpdatePresenceResponse;
  return proto.yorkie.v1.UpdatePresenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yorkie.v1.UpdatePresenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yorkie.v1.UpdatePresenceResponse}
 */
proto.yorkie.v1.UpdatePresenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yorkie.v1.UpdatePresenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yorkie.v1.UpdatePresenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yorkie.v1.UpdatePresenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yorkie.v1.UpdatePresenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.yorkie.v1);
